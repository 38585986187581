import React, {useState} from 'react';
import { connect } from 'react-redux'
import {LANGUAGE_ES, LANGUAGE_EN} from 'redux/actions/actionsLanguage'
import Button from 'Components/Button'

const TEXTS={
    BUTTON:{
        SV:'Skicka in anmälan',
        EN:'Send registration', 
        ES:'Enviar notificacón',
    }
}


const TEST_OBJECT = {
    role:'LEADER',
    firstName: 'Per Eskilson',
    lastName: 'Eskilson',
    email: 'paelsis@hotmail.com',
    phone: '0733789747',
    address: 'Plönegatan 8',
    firstNamePartner:'Jovana',
    lastNamePartner:'Jankov',
    addressPartner:'Ljungbergsgatan 10',
    phonePartner:'0737570724', 
    emailPartner:'jovana_schon@hotmail.com',
    food:'MEAT',
    allergies:'none',
    comment:'Friends'
}


const formTemplate = ({language, globalStyle, fields, handleSubmit}) => {
    //const [value, setValue] = useState({})
    const development = process.env.NODE_ENV === 'development'
    const [value, setValue] = useState(development?TEST_OBJECT:{})
    const handleChange = e => setValue({...value, [e.target.name]:e.target.type==='checkbox'?e.target.checked?e.target.checked:undefined:e.target.value})

    const renderField = fld => {
        const show = fld.notHiddenIf?value[fld.notHiddenIf]?value[fld.notHiddenIf]!=='NO':false:true
        const radioValues = fld['radioValues' + language]?fld['radioValues' + language].split(','):fld.values?fld.values:undefined
        const label = fld['label' + language]?fld['label' + language]:fld.label?fld.label:'No label'
        if (show) {
            switch (fld.type) {
                case 'radio':
                    return(
                    <p>
                        {label}&nbsp;{fld.required!==null?<sup style={{color:'red'}}>*</sup>:null}<br/>
                        {radioValues?radioValues.map(val =>
                            <>
                            <input type={fld.type} value={val} name={fld.name} checked={value[fld.name] === val} onChange={handleChange}/>&nbsp;{val}
                            </>
                        ):[]}
                    </p> 
                    )
                case 'checkboxes':
                    return(
                    <p>
                        {label}&nbsp;{fld.required?<sup style={{color:'red'}}>*</sup>:null}<br/>
                        {fld.names.map(name =>
                            <>
                            {name}&nbsp;<input keytype={'checkbox'} name={name} required={fld.required} onChange={handleChange}/>
                            </>
                        )}
                    </p> 
                    )
                case 'textarea':
                    return(  
                        <p>
                            {label}&nbsp;{fld.required?<sup style={{color:'red'}}>*</sup>:null}&nbsp;<br/>
                            <textarea 
                                type={fld.type} 
                                cols={fld.cols?fld.cols:30}
                                rows={fld.rows?fld.rows:fld.numberOfRows?fld.NumberOfRows:6}
                                maxlength={fld.maxLength?fld.maxLength:300}
                                value={value[fld.name]} name={fld.name} onChange={handleChange} required={fld.required} />
                        </p>
                    )    
                default:
                    return(
                        <p>
                            {label}&nbsp;{fld.required?<sup style={{color:'red'}}>*</sup>:null}&nbsp;<br/>
                            <input type={fld.type} value={value[fld.name]} name={fld.name} style={fld.style} onChange={handleChange} required={fld.required} />
                        </p>
                    )
            }
        } else {
                return(null)
        }    
    
    }
    
    

    return(
        <form onSubmit={e=>handleSubmit(e, value)}>
            {fields.map(fld => renderField(fld))}     

            <Button type="submit" variant="outlined" className="button" style={{color:globalStyle.color, borderColor:globalStyle.color}}>
                {TEXTS.BUTTON[language]}
            </Button>

        </form>
    )
}

const mapStateToProps = state => {
    return {
      language: LANGUAGE_EN,
      globalStyle:state.style
    }
  }
  
export default connect(mapStateToProps)(formTemplate)






 