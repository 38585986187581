import {BOOKING_STATUS, AVA_STATUS} from './Const'

// Static utility functions
export const acceptKeys = (obj, keys) => {
    return Object.keys(obj)
        .filter(k => keys.includes(k))
        .map(k => Object.assign({}, {[k]: obj[k]}))
        .reduce((res, o) => Object.assign(res, o), {});
}

const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const wait = (ms, callback) => {
    sleep(ms).then(callback);
} 

export const bookingStatus = (leader, avaStatus) => {
    return(
        leader && avaStatus === AVA_STATUS.CLOSED_FOR_LEADER?
            BOOKING_STATUS.WAITLISTED_FOR_FOLLOWER
        :!leader && avaStatus === AVA_STATUS.CLOSED_FOR_FOLLOWER?
            BOOKING_STATUS.WAITLISTED_FOR_LEADER
        :avaStatus === BOOKING_STATUS.CLOSED_BY_REQUEST?
            BOOKING_STATUS.WAITLISTED_FOR_CANCELLATION
        :avaStatus === BOOKING_STATUS.COMPLETELY_CLOSED?
            BOOKING_STATUS.WAITLISTED_FOR_CANCELLATION
        :
            BOOKING_STATUS.CONFIRMED
    )
}



export const isMobileDevice = () => {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
};


