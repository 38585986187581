import { connect } from 'react-redux';
import withRecords from 'Components/Table/withRecords';
import GetRegistrationMapMap from './GetRegistrationMapMap'
import lightGreen from '@material-ui/core/colors/lightGreen';
import {TBL_TEACHER_NOTE} from 'Settings/Const'

const GROUP_BY_DEF_ARR= (language) => [
    {
        groupByField:'productId',
        headerFields:['courseName', 'dayname', 'time', 'siteName', 'startDate'], 
        listSortBy:['textId'],
        headerProps:{
            open:{
                width:'100vw', 
                maxWidth:'100%', 
                padding:10,
                backgroundColor:lightGreen[800]
            }, 
            closed:{
                width:'80vw',
                maxWidth:'100%', 
                backgroundColor:lightGreen[700], 
            }
        }    
    },
];

const VIEW_FIELDS=['courseDate', 'textBody'];
const UPDATE_VIEW=['textBody'];
const UPDATE_FIELDS=['textBody'];
const PRESENCE = 'PRESENCE'

const mapStateToProps = (state) => {
    return {
        username: state.user.username,
        password: state.user.password,
        language: state.language,
        url:'/getTeacherNote?tableName=' + TBL_TEACHER_NOTE + '&language=' + state.language,
        groupByDefArr:GROUP_BY_DEF_ARR(state.language),
        tableUpdate:TBL_TEACHER_NOTE,
        viewFields:VIEW_FIELDS,
        urlUpdate:'/admin/updateTable',
        updateView:UPDATE_VIEW,
        updateFields:UPDATE_FIELDS,
    }
}    

export default connect( 
    mapStateToProps,
) (withRecords(GetRegistrationMapMap));    
