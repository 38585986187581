import React, {Component} from 'react';
import {connect } from 'react-redux'
import {getEvents} from './getEvents'
import {tkColors} from 'Settings/tkColors'
import pink from '@material-ui/core/colors/pink'
//import moment from 'moment';
import moment from 'moment-with-locales-es6'
import {CALENDAR} from 'Settings/config'
import {LANGUAGE_SV, LANGUAGE_ES, LANGUAGE_EN} from 'redux/actions/actionsLanguage'
import Marquee from 'react-smooth-marquee';

const CULTURE = (language) => language===LANGUAGE_SV?'sv':language===LANGUAGE_ES?'es':'en'
const highlight = pink[100];

const TEXTS = {
    button:{
        SV:'Info',
        ES:'Info',
        EN:'Info',
    },
    GO_BACK:{
        SV:'Stäng',
        ES:'Cerrer',
        EN:'Close',
    },
    DANCE:{
        SV:'Dansa',
        ES:'Bailar',
        EN:'Dance',
    },
    CLASSES:{
        SV:'Lektioner',
        ES:'Clases',
        EN:'Classes',
    },
    UPCOMING_TODAY:{
        SV:'I dag',
        ES:'Hoy',
        EN:'Today',
    },
    NOTHING_TODAY:{
        SV:'Ingen milonga eller praktika idag',
        ES:'No hay milonga o praktika hoy',
        EN:'No milonga or praktika today',
    },
    THIS_WEEKS_EVENTS:{
        SV:'Kommande veckas dans',
        ES:'Sesiones de baile en una semana ...',
        EN:'Dance within one week ...',
    },
    PREREG_INFO:{
        SV:'Föranmälan göres genom att klicka på eventet i kalendern',
        ES:'La preinscripción se realiza en el calendario',
        EN:'Pre-registration is made in the calendar',
    },
    CLOSE:{
        SV:'Stäng',
        ES:'Cersa',
        EN:'Close',
    }
}



let styles = {
    root:active=>({
        position:'relative',
        overflowX: 'auto',
        opacity:active?1:0,
        transition: '1500ms all ease',
    }),
    ongoing: {
        padding:1, margin:1,
        color:tkColors.Purple.Dark, 
    },
    upcoming: {
        padding:0, margin:0,
        opacity:0.7,
    }

};

const _useSingleDay = (currentBreakpoint, breakpoints) => (
    breakpoints[currentBreakpoint] <= breakpoints.mobile?false
   :breakpoints[currentBreakpoint] <= breakpoints.mobileLandscape?true
   :breakpoints[currentBreakpoint] <= breakpoints.tablet?false
   :breakpoints[currentBreakpoint] <= breakpoints.tabletLandscape?true
   :breakpoints[currentBreakpoint] <= breakpoints.desktop?false
   :breakpoints[currentBreakpoint] <= breakpoints.desktopLarge?false
   :breakpoints[currentBreakpoint] <= breakpoints.desktopWide?false
   :false
)


/**
 * This example allows you to set a date range, and to toggle `autoOk`, and `disableYearSelection`.
 */
class Calendar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fontSize:'small',
            events:[],
            active:false,
            clicked:false,
            preRegister:false,
        };
        this.onClick = this.onClick.bind(this);
        this.renderEvents = this.renderEvents.bind(this);
    }

    loadEvents(cal, ndays) {
        moment.locale(CULTURE(this.props.language))
        // Milongas or practicas during within current day
        const dateTimeStart = moment().startOf('day')
        const dateTimeEnd = moment().endOf('day').add(ndays, 'days')


        try {
            getEvents(
                cal.calendarId,
                cal.apiKey,
                (events) => {this.setState({events, active:true, clicked:ndays>0?true:false, preRegister:events.find(it => it.title.indexOf('anmälan')>=0)?true:undefined})},
                dateTimeStart.format('YYYY-MM-DD') + 'T00:00:00Z', 
                dateTimeEnd.format('YYYY-MM-DD') + 'T23:59:00Z',
                this.props.language,
            );
        } catch(e) {                                
            this.setState({events:[], active:true})
            let errMessage = 'ERROR:' + e.message 
            console.log(errMessage);
        }    
    }    



    // invoked immediately after a component is mounted
    componentDidMount () {
        this.loadEvents(CALENDAR.SOCIAL, 0)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.language !== nextProps.language)  {
            moment.locale(CULTURE(nextProps.language));
        } 
    }

    onClick() {
        this.setState({fontSize: this.state.fontSize==='small'?'large':'small'})
        console.log('defaultFontSize', this.state.defaultFontSize)
    }

    renderUpcomingWeek(fontSize) {
        return(
            this.props.noButton === undefined?
                this.state.clicked === true?            
                    <>                   
                        <p/>
                        {this.state.preRegister?<p style={{padding:5, margin:5, fontSize}}>{TEXTS.PREREG_INFO[this.props.language]}</p>:<p/>}
                        <button className="button" style={{border: "1px solid grey", padding:2, opacity:0.4, size:'small', color:'grey'}}onClick={()=>this.loadEvents(CALENDAR.SOCIAL, 0)}>{TEXTS.CLOSE[this.props.language]}</button>
                    </>
                :        
                    <button className="button" style={{border: "1px solid grey", padding:2, opacity:0.4, size:'small', color:'grey'}}onClick={()=>this.loadEvents(CALENDAR.SOCIAL, 6)}>{TEXTS.THIS_WEEKS_EVENTS[this.props.language]}</button>
            :null    
        )
    }

    renderEvents () {
        const fontSize = 'small'
        return(
            <div>
                {this.state.events.length > 0?
                    this.state.events.map((event, index) =>
                        <div key={index} style={{margin:7}}>
                            {
                                moment() < moment(event.start) ? // Has not started yet
                                    <div>
                                        <p style={{...styles.upcoming, fontSize}}>{moment(event.start).calendar()  + ' - ' + moment(event.end).format('LT') + ' ' + event.location}</p>
                                        <p style={{...styles.upcoming, fontSize}}>{event.title}</p>
                                    </div>    
                                :moment() <= moment(event.end) ? // Has started, but not ended yet
                                    <Marquee key={index}>
                                        <p style={styles.ongoing}>{moment(event.start).calendar() + ' - ' + moment(event.end).format('LT') + ' ' + event.location}</p>
                                        <p style={styles.ongoing}>{event.title}</p>
                                    </Marquee>
                                :null  // Has ended    
                            }
                        </div>
                    )
                :        
                    <p>
                        {TEXTS.NOTHING_TODAY[this.props.language]}
                    </p>        
                }

                {this.renderUpcomingWeek(fontSize)}

            </div>
        )
    }    

    render() {
        const style = {...styles.root(this.state.active), ...this.props.style?this.props.style:{}, transform:undefined}
        return (
            <div style={style} >  
                {this.renderEvents()}
            </div>
        )
    }
} 

const mapStateToProps = (state) => {
    return {
        language: state.language,
    }
}    

export default connect(mapStateToProps)(Calendar)
