import React, {useState} from 'react';
import Weekdays from 'Settings/Weekdays';
import tkColors, {boxShadowValue} from 'Settings/tkColors';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Button from 'Components/Button'
import MessageIcon from '@material-ui/icons/Message';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import deleteRecord from 'functions/deleteRecord'
import EmailIcon from '@material-ui/icons/Email';
import {DateToDayname} from 'Settings/Weekdays';
import TextShow from 'Components/Text/TextShow'
import {TBL_TEACHER_NOTE} from 'Settings/Const'
import moment from 'moment-with-locales-es6'
import { ITEM_TYPE } from './Const';


export const VIEW_1 = 'VIEW_1'
export const VIEW_2 = 'VIEW_2'


const TEXTS = {
    'DOWNLOAD_XLS_LABEL':{
        'SV':'Ladda ner XLS-ark',
        'ES':'Descargar como archivo XLS' ,
        'EN':'Download as XLS'
    },   
}

let styles = {
    table: {
        // marginLeft:'auto',
        // marginRight:'auto',
        fontSize:15,
        fontWeight: 'lighter',
        borderCollapse:'collapse',
        border:'1px solid',
        borderColor:tkColors.border,
        color:tkColors.Text.Dark,
        backgroundColor:tkColors.background
    },
    tbody: {
      overflowX:'auto',
      margin:10,
      border:'0.1px solid',
      borderColor:tkColors.border,
    },  
    tr:{
        borderBottom:'0.1px solid',
        borderColor:tkColors.border,
    },    
    th: {
        fontSize:14, 
        fontWeight:500,
        paddingBottom: 4,
        paddingLeft: 2,
        paddingRight:2, 
        height:12,
        color:tkColors.background,
        backgroundColor:tkColors.Text.Dark,
        overflowWrap:'normal',
        cursor:'pointer'
    },
    td: {
        verticalAlign:'top',
        paddingLeft: 2,
        paddingRight:2, 
        maxWidth:300,
        overflowWrap:'normal',
    },
    msgButton:{
        backgroundColor:tkColors.background,
        boxShadow: boxShadowValue(tkColors.Marathon.Light),
    },
    header: {
        open: {
            fontSize:15,
            fontWeight:400,
            marginLeft:'auto',
            marginRight:'auto',
            marginBottom:2,
            textAlign:'center',
            color:'white',
            transition: '0.5s ease'
        }, 
        closed:{
            fontSize:18,
            fontWeight:600,
            marginLeft:'auto',
            marginRight:'auto',
            marginBottom:2,
            textAlign:'center',
            color:'white',
            transition: '0.5s ease'
        } 
    }
}

function _validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

const _type = (row, fld) => 
    (fld==='deleted'|| fld==='present')?'checkbox'
    :typeof row[fld]

const _viewValue = (row, fld, language) =>
    row[fld]===undefined?undefined
    :fld.includes('emailPartner') && row[fld]===undefined?undefined
    :fld==='dayOfWeek'?Weekdays[language][row[fld]?row[fld]:1]
    :fld==='present'?row[fld]==1?'present':'-'
    :fld==='fullName'?row.email?<a href={"mailto:" + row[fld]}>{row[fld]}</a>:row[fld]
    :fld==='phone'?<a href={"tel:" + row[fld]}>{row[fld]}</a>
    :fld==='textBody'?<div dangerouslySetInnerHTML={{__html: row[fld]}} />
    :fld.indexOf('jsonString')!==-1?JSON.stringify(row[fld])
    :fld.indexOf('json')!==-1?Object.values(row[fld]).map(it => row[fld].json[it[1]]+ ' ')
    :_validateEmail(row[fld])?row.emailPartnerAlert && fld ==='emailPartner'?row[fld]:<a href={"mailto:" + row[fld]}>{row[fld]}</a>                          
    :row[fld]


const _stylesTr = (row) => {
    const opacity = row.deleted?row.deleted==1?0.7:1.0:1.0
    const textDecoration = row.deleted?row.deleted==1?'line-through':'none':'none'
    const backgroundColor = row.handled?row.handled==1?'#7FFFAA':'transparent':'transparent'
    const color = row.status?row.status === 'OK'?tkColors.Text.Dark:row.status === 'WL' || row.status==='WF'?'orange':'red':'black'
    const fontWeight = row.shallPay?row.shallPay === 0?'bold':'lighter':'lighter'
    const style = {...styles.tr, opacity, color, backgroundColor, fontWeight, textDecoration}
    return style;
}

const _stylesTd = (row, fld) => {
    const partnerMissingEmail = row.emailPartnerAlert?row.emailPartnerAlert.includes('MISSING')?fld.includes('emailPartner'):false:false
    const partnerWrongEmail = row.emailPartnerAlert?row.emailPartnerAlert.includes('@')?fld ==='emailPartner':false:false
    const opacity = partnerWrongEmail||partnerMissingEmail?0.7:1.0
    const color =  partnerMissingEmail?'red':'black'
    const fontStyle = partnerMissingEmail?'oblique':'normal'
    const style = {...styles.td, opacity, color, fontStyle}
    return style;
}

const _viewRow = (row, viewFields, language) => 
    viewFields.map((fld) => 
        <td style={_stylesTd(row, fld)}>
            {_viewValue(row, fld, language)}
        </td>
    )

const viewRowWithButton = (row, rowIndex, props) => {
    const text = row.firstName + ' ' + row.lastName
    return(
        <tr key={rowIndex} style={_stylesTr(row)}>
            {_viewRow(row, props.viewFields, props.language)}
            {row.message?
                <td style={styles.td} onClick={() => alert(row.message)}> 
                    <Tooltip title={'Message from customer'}>
                        <MessageIcon style={{cursor:'pointer'}} />
                    </Tooltip>
                </td>
            :
                <td />
            }
            {props.updateView?props.updateView.length >0?
                <td style={styles.td}> 
                    <Tooltip title={'Edit row'}>
                        <EditIcon style={{color:'green', cursor:'pointer'}} onClick={() => props.toggleEdit(row)}/>
                    </Tooltip>
                </td>
            :null:null}
                <td style={styles.td} onClick={() => handleDelete(row.id, props, text)}> 
            <Tooltip title={'Delete in database'}>
                <DeleteForeverIcon style={{color:'red', cursor:'pointer'}}  />
            </Tooltip>
            </td>
        </tr>
    )            
}    

const viewRowWithoutButton = (row, rowIdx, props) =>
    <tr key={rowIdx} style={{..._stylesTr(row), opacity:row.opacity?row.opacity:1.0}}>
        {_viewRow(row, props.viewFields, props.language)}
    </tr>            

const _inputOrView = (row, fld, colIndex, props) => {
    const type=_type(row, fld);
    const isView = (props.viewFields?props.viewFields.some(it => it === fld):true) 
    const isUpdate = props.updateView?props.updateView.some(it => it === fld):false
    console.log('inputOrView fld:', fld, ' row[fld]:', row[fld])
    return(
        isUpdate? 
            type==='checkbox'?
                <input 
                    key={colIndex}
                    style={{width:20}}
                    type={type}
                    name={fld} 
                    checked={(row[fld]==1)?true:false}
                    onChange={e=>props.handleChangeId(e, row.id)} 
                />
            :    
                <input 
                    key={colIndex}
                    style={{width:type==='string'?100:60}}
                    type={type}
                    name={fld} 
                    value={row[fld]}
                    onChange={e=>props.handleChangeId(e, row.id)}
                />
        :isView?
            _viewValue(row, fld, props.language)    
        :null    
    )    
}


const editFields = (row, props) => 
    props.viewFields.map((fld, colIndex) => 
        <td key={fld} style={styles.td}>
            {_inputOrView(row, fld, colIndex, props)}
        </td>
    )

const handleDelete = (id, props, text) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure you want to delete registration of " + text + " ?")) {
        props.handleDeleteById(id)
        deleteRecord(props.tableUpdate, id) 
    } 
}    

const editRowWithButton = (row, rowIndex, props) => {
    const {viewFields, toggleEdit} = props
    const text = row.firstName + ' ' + row.lastName
    return (
        <tr key={rowIndex} style={_stylesTr(row)}>
            {editFields(row, props)}
            {row.message?
                <td key={'messageIcon'} style={styles.td} onClick={() => alert(row.message)}> 
                    <MessageIcon />
                </td>
                :
                <td />
            }
            {props.updateView?props.updateView.length >0?
                <td key={viewFields.length + 1} style={styles.td}> 
                    <Tooltip title={'Save changes in database'}>
                    <SaveIcon style={{color:'orange', cursor:'pointer' }}  onClick={() => toggleEdit(row)}/>
                    </Tooltip>  
                </td>
            :null:null}
            <td key={'deleteForever'} style={styles.td} onClick={() => handleDelete(row.id, props, text)}> 
                <DeleteForeverIcon style={{color:'red', cursor:'pointer'}} />
            </td>
        </tr>            
    )
}

const editRowWithoutButton = (row, rowIndex, props) => 
    <tr key={rowIndex} style={_stylesTr(row)}>
        {editFields(row, props)}
    </tr>            

const isPaid = (pr) => {
    return pr?pr.amount?pr.paidAmount?pr.paidAmount === pr.amount?true:false:false:false:false;
}
const isPartiallyPaid = (pr) => {
    return pr?pr.amount?pr.paidAmount?pr.paidAmount === pr.amount?true:false:false:false:false;
}

export const Header = ({list, headerFields, language, open, handleOpen, headerProps}) => {
    const row = list[0] 
    const cntTotal =list.filter(it => it.deleted?it.deleted == 0:true).length  
    const cntLeaders=list.filter(it => it.leader?it.leader == 1 && (it.deleted?it.deleted == 0:true):false).length 
    const cntFollowers=list.filter(it => it.leader?it.leader == 0 && (it.deleted?it.deleted == 0:true):false).length
    const cntOnline=list.filter(it => it.danceSite?it.danceSite === 'ONLINE' && (it.deleted?it.deleted == 0:true):false).length
    const cntSite=list.filter(it => it.danceSite?it.danceSite === 'SITE' && (it.deleted?it.deleted == 0:true):true).length
    const genderImbalance = Math.abs (cntLeaders - cntFollowers) > 3
    let style=open?{...styles.header.open, ...headerProps.open}:{...styles.header.closed, ...headerProps.closed}
    return(
        <div key={row.id} style={style} onClick={handleOpen}>
            {headerFields.map(fld =>
                fld==='dayOfWeek'?
                    <span key={row[fld]}>
                        {Weekdays[language][row.dayOfWeek?row[fld]-1:1-1]}&nbsp;&nbsp;
                    </span>
                :
                    <span key={fld} >
                        {row[fld]} &nbsp;&nbsp;
                    </span>

            )}     
            ({cntLeaders || cntFollowers?cntLeaders + ' förare, ' + cntFollowers + ' följare, ' + cntSite + ' danslokal, ' + cntOnline + ' online, ' + cntTotal + ' tot':cntTotal + ' tot'})
        </div>    
    )
}

const maillist = (list, fld) => list.map(it => it[fld]?it[fld]:null).join(', ')

/* Replace underscore with blank {row[fld].replace(/_/gi,' ')} &nbsp;&nbsp; */
const _view1 = (props) => {
    const {list, viewFields, edit, toggleEditList, language} = props
    console.log('_view1 list:', list)
    return (
        <div>
            <ReactHTMLTableToExcel
                    className="btn btnInfo"
                    table="table-map-view-1"
                    filename={props.tableUpdate?props.tableUpdate:"ReportExcel"}
                    sheet="Sheet"
                    buttonText="Download as XLS"
            />

            <table id={'table-map-view-1'} style={styles.table}>
                <thead>
                    {viewFields.map(fld => 
                        <th key={fld} style={styles.th} onClick={()=>fld.includes('email')?null:props.sortStateListByKey(fld)}>    
                            {fld.includes('email')?
                                <>
                                    {fld}&nbsp;
                                    <a href={'mailto:?bcc=' + maillist(list, fld) + '&subject=Mail från TK'} target="_top">
                                        <Tooltip title={'Send mail to group'}>  
                                        <EmailIcon style={{cursor:'pointer', fontSize:'small', color:'lightBlue'}} />
                                        </Tooltip>
                                    </a>
                                </>
                            :fld
                            }
                        </th>
                    )}    
                    <th key={'Message'} style={styles.th}>Message</th>
                    {props.updateView?props.updateView.length >0?
                        <th key={'Edit'} style={styles.th}>Edit</th>
                    :null:null}
                    <th key={'Delete'} style={styles.th}>Delete</th>
                </thead>
                <tbody>
                    {list.map((row, rowIndex)=> 
                        edit[row.id]?editRowWithButton(row, rowIndex, props)
                        :viewRowWithButton(row, rowIndex, props)
                    )} 
                </tbody>
            </table>
        </div>

    )
}

/*
    <Button onClick={() => toggleEditList(list)}>
    {props.edit[list[0].id]?'Save All':'Edit All'}  
    </Button>    
    {props.edit[list[0].id]?<UpdateProductId fromProductId = {list[0].productId} handleResult={(data) => props.fetchListAgain()} />:null}
*/

const _view2 = (props) => {
    const {list, viewFields, edit, toggleEditList, replaceById, language} = props
    const editSingleId = edit[list[0].id]
    
    console.log('_view2List:', list)

    const toggleEditListPrompt = (list) => {
        if (!editSingleId) {
            const courseDate = prompt('Please enter course date', list.length>0?list[list.length - 1].courseDate?list[list.length-1].courseDate:moment().format('YYYY-MM-DD'):moment().format('YYYY-MM-DD'))
            const listModified = list.map(it => ({...it, courseDate, dayname:DateToDayname(courseDate)}))
            console.log('list:', list, 'listModified:', listModified)
            replaceById(listModified)
        }
        toggleEditList(list)
    }
    const textId = list.length > 0?list[0].productId !== undefined?list[0].productId + list[0].courseDate:'':''
    return (
        <div>

            <Button variant="outlined" onClick={() => toggleEditListPrompt(list)}>
                {editSingleId?'Save':'Edit'}  
            </Button>    
            <ReactHTMLTableToExcel
                    className="btn btnInfo"
                    table="table-map-view-2"
                    filename={props.tableUpdate?props.tableUpdate:"ReportExcel"}
                    sheet="Sheet"
                    buttonText="Download as XLS"
            />
            <table id={'table-map-view-2'} style={styles.table}>
                <thead>
                    {viewFields.map((fld, ndx) => 
                        <th key={ndx} style={styles.th} onClick={()=>fld.includes('email')?null:props.sortStateListByKey(fld)}>    
                            {fld.includes('email') ?
                                        <a href={'mailto:?bcc=' + maillist(list, fld) + '&subject=Mail från TK'} target="_top">
                                            {fld}&nbsp;
                                            <Tooltip title={'Send mail to group'}>  
                                            <EmailIcon style={{cursor:'pointer', fontSize:'small'}} />
                                            </Tooltip>
                                        </a>
                            :fld}
                        </th>
                    )}    
                </thead>
                <tbody style={styles.tbody}>
                    {list.map((row, rowIdx)=> 
                        edit[row.id]?editRowWithoutButton(row, rowIdx, props)
                        :viewRowWithoutButton(row, rowIdx, props)
                    )} 
                </tbody>
            </table>
            {props.insertTeacherNote && props.edit[list[0].id]!==undefined?
                <TextShow tableName={TBL_TEACHER_NOTE} url={'/getTexts?tableName=' + TBL_TEACHER_NOTE} groupId={'PRESENCE'} textId={textId} language={language} /> 
            :null}    
        </div>
    )
}

const _defaultView = () => {
    return (
        <div style={styles.table}>
            Wrong view option in UtilsMap.js        
        </div>
    )

}

const GetTableView = (props) => {
    switch (props.view) {
        case VIEW_1:return _view1(props);
        case VIEW_2:return _view2(props);
        default:return _defaultView();
    }
}

export default GetTableView
