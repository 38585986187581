import React, {useEffect, useState} from 'react';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import tkColors from 'Settings/tkColors';

const HTMLEditor = (props) => {
    const [value, setValue] = useState(props.value)
    const [edit, setEdit] = useState(false)
  
    const handleSave = () => {
      setEdit(false);
      props.onCommit(value);
    }
  
    const toggleEdit = () => setEdit(!edit)
    const onChangeHTML = e => setValue(e.target.value)
  
    return (
      <>
        <div>
          {edit? 
            <CloseIcon style={{color:'orange'}} onClick={()=>toggleEdit()} />
          :
            <EditIcon style={{color:tkColors.Olive.Dark}} onClick={()=>toggleEdit()} />
          }  
          {edit?
              <SaveIcon  key={'commit'} onClick={()=>handleSave()} style={{color:'orange'}}/>
          :
              null
          }  
        </div>
        <div>
          {edit?
            <textarea 
              style={props.style}
              name={'value'} 
              cols={100} 
              rows={25}
              value={value} 
              onChange={onChangeHTML}
            />
          :
            <div dangerouslySetInnerHTML={{__html: value}} />      
        }
        </div>
      </>
    );
  }
  

export default HTMLEditor

