
import {store} from 'index.js'
import config from 'Settings/config';
import postPayload from 'functions/postPayload'

const apiBaseUrl=config[process.env.NODE_ENV].apiBaseUrl;
const url = apiBaseUrl + '/admin/deleteRecord'

const deleteRecord = (table, id) => {
    const payload = {table, id}
    const user = store.getState().user
    const username = user?user.username:''
    const password = user?user.password:''
    console.log(url, username, password, payload)
    postPayload(url, username, password, payload, (it) => console.log('Deleted id ='  + id + ' in table ' + table)) 
}

export default deleteRecord
