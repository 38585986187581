import React from 'react';
import { connect } from 'react-redux'
import { withBreakpoints } from 'react-breakpoints'
import Weekdays from 'Settings/Weekdays';
import RegistrationButton from '../School/Registration/RegistrationButton'
import ExpandTextDialog from 'Components/ExpandTextDialog';

const ZOOM_URL = 'https://zoom.us/join'

let styles = {
    tr: {
        padding: 4,
        fontSize: 14,
        height:15,
        verticalAlign:'middle',
    },
    td: {
        verticalAlign:'middle',
        padding: 2,
        minWidth:10,
    },
    tdDiamond: {
        verticalAlign:'middle',
        textDecoration:'none',
        cursor:'pointer',
        color:'yellow',
    }, 
    button: {
        fontSize:'small'
    }
};

const renderExpandedAddress = (course) => (
    course?
        <table>
            <tr>
                <td>{course.teachers}</td>
            </tr>       
            <tr>
                <td>{course.siteName}</td>
            </tr>    
            <tr>
                <td>{course.address}</td>
            </tr>    
            <tr>
                <td>{course.city}</td>
            </tr>    
        </table>  
    :
        <h4>Course not defined</h4>    
)    

const _renderRegistrationLineLarge = (course, color, language) => {
    const range = course.startTime + ' - ' + course.endTime 
    const weekend = course.courseType === 'HK'
    return(
    <tr style={styles.tr}>
        {weekend?null:
            <>
                <td style={styles.td}>{weekend?' ':Weekdays[language][course.dayOfWeek-1]}</td>
                <td style={styles.td}>{weekend?' ':range}</td>
            </>
        }
        <td style={styles.td}>
            <a style={{color}} href={course.urlLocation} >{course.city?course.city:'   '}</a>
            &nbsp;
            {course.online==="1"?<a href={ZOOM_URL} style={styles.tdDiamond}>&#9830;</a>:null}
        </td>
        {/*<td style={styles.td}>{course.courseLength}</td>*/}
        <td style={styles.td}>{course.dayOfWeek?course.startDate:''}</td>
        <td style={styles.td}>
        <ExpandTextDialog
                style={{color}}
                shortText={course.teachersShort}
                title={course['name' + language] + ' (' + course.city + ' ' + Weekdays[language][course.dayOfWeek-1] + ' ' + course.startTime + ')'}
        >
            {renderExpandedAddress(course)}
        </ExpandTextDialog>     
        </td>  
        <td><RegistrationButton reg={course} /></td>          
    </tr>
    )
 }

export const renderRegistrationLineSmall = (course, color, language) => {
    const weekend = course.courseType === 'HK'
    const city = course.city?course.city.substring(0,5):'   '
    return(
        <tr style={styles.tr}>
            {weekend?null
            :
                <>
                    <td style={styles.td}>{weekend?' ':Weekdays[language][course.dayOfWeek-1]}</td>
                    <td style={styles.td}>{weekend?' ':course.startTime}</td>
                </>
            }
            <td style={styles.td}>
                <a style={{color}} href={course.urlLocation}>{city}</a>
                &nbsp;
                {course.online==1?<a href={ZOOM_URL} style={styles.tdDiamond}>&#9830;</a>:null}
            </td>
            {/*<td style={styles.td}>{course.courseLength}</td>*/}
            <td style={styles.td}>{course.dayOfWeek?course.startDate:''}</td>
            <td style={styles.td}>
            <ExpandTextDialog
                    style={{color}}
                    shortText={course.teachersShort}
                    title={course['name' + language] + ' (' + course.city + ' ' + Weekdays[language][course.dayOfWeek-1] + ' ' + course.startTime + ')'}
            >
                {renderExpandedAddress(course)}
            </ExpandTextDialog>     
            </td>            
            {
                <td><RegistrationButton reg={course} /></td>
            }    
        </tr>
    )
}


const RenderRegistrationLine = (props) => {
    const {course, color, breakpoints, currentBreakpoint, language} = props;
    return(breakpoints[currentBreakpoint] < breakpoints.tablet?renderRegistrationLineSmall(course, color, language):_renderRegistrationLineLarge(course, color, language));
}

const mapStateToProps = (state) => {
    return {
        language: state.language,
        color: 'white', 
    }
}    

export default  connect(mapStateToProps)(withBreakpoints(RenderRegistrationLine));    
