import React from 'react';
import GetTableView, {Header, VIEW_1, VIEW_2} from 'Settings/UtilsMap';
import groupBy from 'functions/groupBy';
import compareByMultiple from 'functions/compareByMultiple';

// Recursive groupBy (GetMapMap is called recursively)
const GetMapMap = (props) => {
    const idx=props.index?props.index:0;
    const groupByDef=props.groupByDefArr[idx]?props.groupByDefArr[idx]:undefined
    const groupByField=groupByDef?groupByDef.groupByField:undefined;
    const headerFields=groupByDef?groupByDef.headerFields:undefined;
    const headerSortBy=groupByDef?groupByDef.headerSortBy:undefined;
    const listSortBy=groupByDef?groupByDef.listSortBy:undefined;
    const headerProps=groupByDef?groupByDef.headerProps:null;


    console.log('groupByDef:', groupByDef)
    console.log('groupByField:', groupByField)
    console.log('headerFields:', headerFields)
    console.log('headerProps:', headerProps)
    //let filterlist = props.productTypeArr?props.list.filter(it => props.productTypeArr.find(pt =>  pt === it.productType)!==undefined):props.list;
    let filterList = props.list
    filterList = headerSortBy?filterList.length>0?filterList[0][headerSortBy]?filterList.sort((a,b) => a[headerSortBy].localeCompare(b[headerSortBy]))
        :filterList
        :filterList
        :filterList 
    const fieldMap = groupByField?groupBy(filterList, it => it[groupByField]?it[groupByField].replace(/\s+/g, ''):it):null;

    console.log('GetMapMap: filterlist', filterList)

    // If one menu is open, the other options at the same level shall be hidden
    let oneOpen=[...fieldMap.keys() ].find(key=>props.open[idx][key]);
    return(
        <div>   
            <div>
                {[...fieldMap.keys() ].map(key =>  
                <div key={key}>
                    {!oneOpen||props.open[idx][key]?
                        <Header 
                            list={fieldMap.get(key)}
                            headerFields={headerFields}
                            language={props.language} 
                            open={props.open[idx][key]} 
                            handleOpen={()=>props.handleOpen(idx, key)} 
                            headerProps={headerProps} 
                        />
                    :
                        null
                    }                 
                    {props.open[idx][key]?
                        <div>
                            {idx < props.groupByDefArr.length-1?
                                <GetMapMap {...props} list={fieldMap.get(key)} index={idx+1}/>
                            :   
                                <GetTableView
                                    {...props}
                                    view={props.urlUpdate?VIEW_1:VIEW_2} 
                                    list={fieldMap.get(key).sort((a,b)=>compareByMultiple(a,b, listSortBy))}
                                />
                            }
                        </div>
                    :null}    
                </div>    
                )}
            </div>
        </div>
    )
}

export default GetMapMap
