import React, {useState} from 'react';
import HTMLEditor from './HTMLEditor'
import DraftEditor from './DraftEditor'
import TextEditor from './RichTextEditor'
import tkColors from 'Settings/tkColors';


export const TEXT_EDITOR = 'TEXT_EDITOR'
export const HTML_EDITOR = 'HTML_EDITOR'
export const DRAFT_EDITOR = 'DRAFT_EDITOR'

const styles = {
  textButton:{
    fontSize:'small', 
    padding:1, 
    color:'green', 
    borderColor:'green'
  },  
  htmlButton:{
    fontSize:'small', 
    padding:1, 
    color:'red', 
    borderColor:'red', 
    backgroundColor:'yellow'
  },
  draftButton:{
    fontSize:'small', 
    padding:1, 
    color:'white', 
    borderColor:'white', 
    backgroundColor:'red'
  },
  textEditor:{
    fontSize:'normal', 
    border:'1px solid',
    borderColor:tkColors.Olive.Dark,
    padding:1,
    color: tkColors.Olive.Dark,
    backgroundColor:'lightGrey',
    cursor:'pointer',
  },
  htmlEditor:{
    fontSize:'normal',
    border:'1px solid',
    borderColor:tkColors.Olive.Dark,
    padding:1,
    color: tkColors.Olive.Dark,
    backgroundColor:'lightYellow',
    cursor:'pointer',
  },
}


const TextAndHtmlEditor = props => {
  const [editor, setEditor] = useState(TEXT_EDITOR)
  const toggleEditor = () => {
    setEditor(editor===TEXT_EDITOR?HTML_EDITOR:TEXT_EDITOR)
  }
  return(
    <div>
      <button className="button" 
        style={editor===TEXT_EDITOR?styles.textButton:styles.htmlButton}
        onClick={()=>toggleEditor()}
      >  
        {editor===TEXT_EDITOR?'TEXT EDITOR':'HTML EDITOR'}
      </button>

      {editor === TEXT_EDITOR ?
        <TextEditor {...props} style={styles.textEditor} />
      :editor === HTML_EDITOR ?
        <HTMLEditor {...props} style={styles.htmlEditor} />
      :
        <DraftEditor {...props} style={styles.textEditor} />

    }

    </div>
  )  
}

export default TextAndHtmlEditor