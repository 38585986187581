import React, {Component} from 'react';
import PropTypes from 'prop-types'; 
import tkColors from 'Settings/tkColors'
import {loadDropdownList} from 'Components/Table/loadDropdownList';
import groupBy from 'functions/groupBy';
import {DateToDaynameShort} from 'Settings/Weekdays';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const NO_VALUE = ''

const styles = {
  root:{
      position:'relative',
      backgroundColor:tkColors.background,
      fontSize:14,
  },
  header:{
    textAlign:'center',
    color:tkColors.Purple.Light,
    fontSize:36,
    fontWight:600,
    opacity:0.4,
  },
  table: {
      backgroundColor:tkColors.background,
      // borderCollapse:'collapse',
      borderColor:tkColors.border,
      whiteSpace:'nowrap',
  },
  add: {
    color:tkColors.Text.Dark,
    backgroundColor:'pink',
  },
  selectList: {
    backgroundColor:tkColors.Purple.Light,
    color:tkColors.Text.Light,
  },
  input: {
    width:100,
    backgroundColor:'lightYellow',
  },
  inputNumber: {
    width:20,
    backgroundColor:'lightYellow',
  },
  inputList: {
    maxWidth:400,
    color:tkColors.Text.Dark,
  },
  inputNumberList: {
    width:20,
    color:tkColors.Text.Dark,
  },
  textarea: {
    backgroundColor:'lightYellow',
  },
  textareaList: {
    backgroundColor:tkColors.Purple.Light,
    color:tkColors.Text.Light,
  },
  tbody: {
    border:2, 
    cellpadding:2,
  },  
  trNoBorder: {
    height:20,
    verticalAlign:'top',
    padding: 5,
    fontSize: 15,
  },
  tr: {
      height:20,
      verticalAlign:'top',
      padding: 5,
      fontSize: 12,
      border:'0.1px solid',
      borderColor:tkColors.border,
  },
  th: {
    verticalAlign:'bottom',
    padding: 8,
    borderBottom:'0.1px solid',
    borderColor:tkColors.border,
    fontSize:12,
    color: 'grey',
  },
  thSelect: {
    verticalAlign:'bottom',
    padding: 8,
    borderBottom:'0.1px solid',
    borderColor:tkColors.border,
    fontSize:12,
    color: 'grey',
  },
  thInput: {
    verticalAlign:'bottom',
    padding: 8,
    width:'70%',
    borderBottom:'0.1px solid',
    borderColor:tkColors.border,
    fontSize:12,
    color: 'grey',
  },
  tdNoBorder: {
    verticalAlign:'top',
    padding: 8,
    color:tkColors.color,
  },
  td: {
      maxWidth:'60%',
      width:'50%',
      verticalAlign:'top',
      padding: 4,
      color:tkColors.color,
      borderBottom:'0.1px solid',
      borderColor:tkColors.border,
  },
};


// More components
class ScheduleEdit extends Component {
  static propTypes = {
    table: PropTypes.string.isRequired,
    handleSave:PropTypes.bool.isRequired,
    templateName:PropTypes.string.isRequired,
    columns:PropTypes.array.isRequired,
    list:PropTypes.array.isRequired,
  };

  constructor() {
      super();
      this.state = {record:{}, selectLists:{}};
      this.clearRecord=this.clearRecord.bind(this);
      this.loadDropdownLists = this.loadDropdownLists.bind(this);
      this.onChange=this.onChange.bind(this);
      this.handleAdd = this.handleAdd.bind(this);
  }

  clearRecord = () => {
    this.setState({record:{}})
  }
 
  loadDropdownLists(columns) {
      columns.filter(col=>col.selectKey?true:false).forEach(col =>  {
        loadDropdownList(
            this.props.username, 
            this.props.password, 
            col.tableName, 
            col.selectKey,
            col.selectValue,    
            (list)=>this.setState({selectLists:{...this.state.selectLists, [col.name]:list}})
        )
      })
  }    
    

  componentDidMount() {
    this.loadDropdownLists(this.props.columns);
    this.clearRecord();
  }        
    
  groupByListByKey (key) {
    const fieldMap = groupBy(this.props.filterList.sort((a,b) => a[key] - b[key]), it => it[key])
    return([...fieldMap.keys()])
  }

  onChange (e) {
    console.log('e.target.name:', e.target.name);
    console.log('e.target.value:', e.target.value);
    this.setState({record:{...this.state.record, [e.target.name]:e.target.value}});  
    
  }

  onChangeChk = (e) => {
    console.log('e.target.name:', e.target.name);
    console.log('e.target.checked:', e.target.checked);
    this.setState({record:{...this.state.record, [e.target.name]:e.target.checked}});  
  }

  _ListElement = (li, col) =>     
      <td key={col.name}>
        {col.type==='select'&&this.state.selectLists[col.name]?
          <select
            name={col.name}
            onChange={(e)=>this.props.handleChangeId(e, li.id)}
            value={li[col.name]?li[col.name]:NO_VALUE}
          > 
            <option name={null} value={NO_VALUE}>No value</option>
            {this.state.selectLists[col.name].map(it =>
                <option 
                  style={styles.enabled} 
                  value={it.key} 
                >
                  {it.value}
                </option>
            )}  
          </select>
        :col.type === 'checkbox'?
            <input type="checkbox" 
              name={col.name} 
              checked={li[col.name]==1?true:false}
              onChange={e=>this.props.handleChangeId(e, li.id)} /> 
        :col.type==='textarea'?
            <textarea 
              style={styles.textareaList}
              name={col.name} 
              cols={25} 
              rows={3} 
              maxLength={300}
              value={li[col.name]?li[col.name]:''} 
              placeholder={col.placeholder?col.placeholder:''} 
              onChange={e=>this.props.handleChangeId(e, li.id)}
              />
        :
            <input 
              key={col.name} 
              style={col.type==='number'?styles.inputListNumber:styles.inputList}
              type={col.type?col.type:'text'}
              name={col.name}  
              value={li[col.name]?li[col.name]:''} 
              selected={col.selected}
              placeholder={col.placeholder?col.placeholder:col.name} 
              onChange={(e)=>this.props.handleChangeId(e, li.id)}
            />
        }    
    </td>

  _compare = (o1, o2) => {
    return(o1.id - o2.id)
  }    

  

  _List = () => (
    this.props.filterList.length > 0)?
      <table style={styles.table} >
        <thead>
          {this.props.columns.filter(it=>it.hidden===true?false:true).map(col=>   
            <th>{col.label}</th>
          )}
          <th>Veckodag</th>
          <th>Delete</th>
        </thead>
        <tbody>
          {this.props.filterList.sort(this._compare).map(li=>
              <tr>
                {this.props.columns.filter(it=>it.hidden===true?false:true).map((col)=> 
                  this._ListElement(li, col)
                )}
                <td style={{fontSize:8}}>{li.startDate?DateToDaynameShort(li.startDate, 'EN') :null}</td>
                <td onClick={()=>this.props.handleDeleteById(li.id)}>
                  <Tooltip title="Delete">
                      <DeleteIcon style={{cursor:'pointer'}}/>
                  </Tooltip>
                </td>
              </tr>
        )}
        </tbody>
       </table>
    :
    <table style={styles.table} >
        <tbody>
            <td>List is empty</td>
        </tbody>
    </table>
 

    



  _SelectRow = () =>
    <form onSubmit={this.handleAdd}>
    <table style={styles.table} >
      <thead>
        {this.props.columns.filter(it=>it.hidden===true?false:true).map(col=>
          col.type==='select'?<th key={col.name} style={styles.thSelect}>{col.label}</th>
          :col.type==='input'?<th key={col.name} style={styles.thInput}>{col.label}</th>
          :<th key={col.name} style={styles.th}>{col.label}</th>
        )}
      </thead>
    <tbody style={styles.tbody} >
      <tr>
        {this.props.columns.filter(it=>it.hidden===true?false:true).map(col=>
            <td key={col.name}>
              {col.type==='select'&&this.state.selectLists[col.name]?
                  <select 
                    name={col.name}
                    onChange={this.onChange}
                    width={80}
                    value={this.state.record[col.name]?this.state.record[col.name]:NO_VALUE}
                    required={col.required}
                  > 
                    <option name={null} value={NO_VALUE}>No value</option>
                    {this.state.selectLists[col.name].map(it =>
                          <option 
                            style={styles.enabled} 
                            value={it.key} 
                          >
                            {it.value}
                          </option>
                    )}  
                </select>
              :col.type === 'checkbox'?
                <input 
                  type={col.type}
                  style={styles.add} 
                  name={col.name} 
                  checked={this.state.record[col.name]==1?true:false} 
                  required={col.required}
                  onChange={this.onChangeChk} /> 
              :col.type==='textarea'?
                <textarea 
                  style={styles.add} 
                  placeholder={col.placeholder?col.placeholder:''} 
                  name={col.name} 
                  cols={25} 
                  rows={3} 
                  maxLength={300}
                  value={this.state.record[col.name]?this.state.record[col.name]:''} 
                  required={col.required}
                  onChange={this.onChange}
                  />
              :
                <input 
                  key={col.name} 
                  style={styles.add} 
                  type={col.type?col.type:'string'}
                  name={col.name}  
                  value={this.state.record[col.name]?this.state.record[col.name]:''} 
                  selected={col.selected}
                  placeholder={col.placeholder?col.placeholder:col.name} 
                  required={col.required}
                  onChange={this.onChange}
                />
            }    
            </td>
        )} 
        <td>
          <IconButton type="submit">
              <AddCircleIcon />
          </IconButton>
        </td>
      </tr>  
    </tbody>
  </table>
  </form>              


  uniqueCheck(rec) {
    let duplicates = []
    this.props.columns.forEach(col => {
        if (col.unique===true) {
           let value = rec[col.name]
           if (this.props.filterList.find(it => value===it[col.name])) {
             duplicates = [...duplicates, col.name]
           }
        }
    })    
    console.log('duplicates:', duplicates)
    if (duplicates.length === 0) {
        return(true)
    } else {    
      alert('Duplicates exists for columns:' + JSON.stringify(duplicates))
      return false
    }
  }         


  handleAdd(e) {
    e.preventDefault();
    const newRecord = {...this.state.record, templateName: this.props.templateName}
    console.log('newRecord:', newRecord)

    if (!this.uniqueCheck(this.state.record)) {
      return
    }

    this.props.handleAdd(newRecord)

    let emptyRecord = {}
    emptyRecord = this.props.columns.map(it => ({...emptyRecord, it}))
    this.setState({record:emptyRecord})
  }

  _EditRecord = () =>
  <tr>
      {this.props.columns.map(col=>
          <td key={col.name}>
              <input 
                name={col.name} 
                value={this.state.record[col.name]?this.state.record[col.name]:''} 
                disabled={!col.upd}
                onChange={this.onChange} 
              />
          </td>
      )}
      <td onClick={this.handleAdd}>  
        <Tooltip title='Add row'>
          <AddCircleIcon style={{cursor:'pointer'}}/>
        </Tooltip>    
      </td>
 </tr>


  render() {
    console.log('record:', this.state.record)
    return(
        this.props.templateName?
          <div style={styles.root}>
            <h2 style={styles.header}>
              Template: {this.props.templateName}
            </h2>
              <div>  
                {this._SelectRow()}  
                {this._List()}
              </div>
        </div>                   
      :null
    )  
  }
};

export default ScheduleEdit
