import React, {useEffect, useState} from 'react';
import RichTextEditor from 'react-rte';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import tkColors from 'Settings/tkColors';

const TextEditor = props => {
  const [value, setValue] = useState(RichTextEditor.createValueFromString(props.value, 'html'))
  const [edit, setEdit] = useState(false)

  const handleSave = () => {
      setEdit(false)
      props.onCommit(value.toString('html'));
  }

  const handleToggle = () => setEdit(!edit)
  return (
      <div>
        <div>
          {edit? 
            <CloseIcon style={{color:'orange', padding:0}} onClick={()=>handleToggle()} />
          :
            <EditIcon style={{color:tkColors.Olive.Dark, padding:0}} onClick={()=>handleToggle()} />
          }  
          {edit?
              <SaveIcon  key={'commit'} onClick={handleSave} style={{color:'orange'}} />
          :
              null
          }  
        </div>
        {edit?
            <RichTextEditor value={value} onChange={val => setValue(val)} />
          :
            <div dangerouslySetInnerHTML={{__html: props.value}} />      
        }
      </div>
  )
}

export default TextEditor

