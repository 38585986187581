import React, {Component} from 'react';
import config from 'Settings/config';
import GetMapMap from './GetMapMap'
import GetMapMapViewAll from './GetMapMapViewAll'
import {postUpdTable, postUpdTableAll} from 'functions/postUpdTable'
import {acceptKeys} from 'Settings/Utils'

let styles = {
    root:{
        flex:1,
        position:'relative',
        marginRight:'auto',
        marginLeft:'auto',
    },
};



export default class GetRegistrationMapMap extends Component {
    constructor() {
        super();
        this.state = { open: []};
        this.handleOpen = this.handleOpen.bind(this)
        this.toggleEdit = this.toggleEdit.bind(this)
        this.toggleEditList = this.toggleEditList.bind(this)
        this.handleReply = this.handleReply.bind(this)
    }

    componentDidMount () {
        const open=this.props.groupByDefArr.map(it => ({}))
        this.setState({open});
    }  

    handleOpen = (index, key) => {
        let collapseAll=false;
        let open=this.state.open.map((op, ix) => {
            if (ix===index) {
                collapseAll=op[key]?true:false;
                return({...op, [key]:op[key]?undefined:true})
            } else if (collapseAll) {
                return({});
            } else {    
                return(op);
            }
        })
        console.log('handleOpen open:', open);
        this.setState({open});
    };

    changeGroupBy(data) {
        // Change the open element to include new groupByStatus
        let open = this.state.open.map((op, index) => {
            if (this.props.groupByDefArr[index].groupByField==='groupByStatus') {
                return({...op, [data.status]:true})
            } else if (this.props.groupByDefArr[index].groupByField==='groupByProductId') {
                return({...op, [data.productId]:true})
            } else {
                return(op);
            }           
        })
        this.setState({open});
        console.log('----- Changing groupByStatus ---- data.status:' + data.status);
        this.props.handleChangeValueById('status', data.status, data.id);    
        this.props.fetchListAgain(); 
        console.log('----- After call to this.props.fetchListAgain()');
    }
    

    handleReply = (statusFlag, data) => {
        console.log('------- GetRegistrationMapMap.handleReply -------');
        if (statusFlag) {
            console.log('status=', statusFlag);
            console.log('data', data);
            this.changeGroupBy(data);
        } else {
            console.log('statusFlag=', statusFlag);
            console.log('data=', data);
        }    
    }    

    handleReplyAll = (statusFlag, data) => {
        console.log('------- GetRegistrationMapMap.handleReplyAll -------');
        if (statusFlag) {
            console.log('status=', statusFlag);
            console.log('data', data);
        } else {
            console.log('statusFlag=', statusFlag);
            console.log('data=', data);
        }    
    }    


    updateTable = (row) =>
    {
        const url=config[process.env.NODE_ENV].apiBaseUrl + this.props.urlUpdate
        const table=this.props.tableUpdate
        const data=acceptKeys(row, this.props.updateFields);
        console.log('Saving id database, id=', row.id)
        console.log('updateDatabase with data:', data)
        postUpdTable(url, table, this.props.username, this.props.password, row.id, data, this.handleReply)
    }

    updateTableAll = (list) =>
    {
        const url=config[process.env.NODE_ENV].apiBaseUrl + this.props.urlUpdateAll
        const table=this.props.tableUpdate
        const data = list.map(it => acceptKeys(it, this.props.updateFields));  
        console.log('updateTableAll: data=', data);
        postUpdTableAll(url, table, this.props.username, this.props.password, data, this.handleReplyAll)
    }


    toggleEdit = (row) => {
        this.props.edit[row.id]?this.updateTable(row):console.log('Do nothing in database');
        this.props.toggleEdit(row.id);
    }

    toggleEditList = (list) => {
        this.props.edit[list[0].id]?this.updateTableAll(list):console.log('Do nothing in database');
        const ids = list.map(it => it.id)
        this.props.toggleEditIds(ids);
    }

    render = () => {
        return(
            <div style={styles.root} >
                &nbsp;&nbsp;    
                <GetMapMap {...this.props}
                    open={this.state.open}
                    handleOpen={this.handleOpen}
                    toggleEdit={this.toggleEdit}
                    toggleEditList={this.toggleEditList}
                />
            </div>
        )    
    }
}

