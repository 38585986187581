import React, {Component} from 'react';
import { connect } from 'react-redux';
import withRecords from 'Components/Table/withRecords';
import GetRegistrationMapMap from './GetRegistrationMapMap'
import backgroundColor from '@material-ui/core/colors/purple';
import red from '@material-ui/core/colors/red';
import indigo from '@material-ui/core/colors/indigo';
import {PRODUCT_TYPE} from 'Settings/Const'



const GROUP_BY_DEF_ARR=[
    {
        groupByField:'scheduleName', 
        headerFields:['scheduleName'],
        headerProps:{
            open:{
                width:'100%', 
                paddingTop:10, 
                paddingBottom:10, 
                backgroundColor:backgroundColor[800],
            }, 
            imbalance:{
            }, 
            closed:{
                width:'50%',
                padding:2, 
                backgroundColor:backgroundColor[700], 
            }
        }    
    },    
];

const VIEW_FIELDS=['firstName', 'lastName', 'email', 'phone', 'leader', 'firstNamePartner', 'lastNamePartner', 'emailPartner', 'danceSite', 'shallPay','havePaid', 'presence', 'productType', 'productId', 'status', 'orderId', 'regDate', 'newsletter', 'deleted'];
const UPDATE_FIELDS = ['firstName', 'lastName', 'email', 'phone', 'leader', 'status', 'firstNamePartner', 'lastNamePartner', 'emailPartner', 'shallPay', 'havePaid', 'presence', 'productId', 'newsletter', 'deleted']        
const UPDATE_VIEW = ['firstName', 'lastName', 'email', 'phone', 'leader', 'status', 'firstNamePartner', 'lastNamePartner', 'emailPartner', 'phonePartner', 'productId']

const mapStateToProps = (state) => {
    return {
        username: state.user.username,
        password: state.user.password,
        language: state.language,
        url:'/admin/getRegistration?productType=course'  + '&language=' + state.language,
        groupByDefArr:GROUP_BY_DEF_ARR,
        tableUpdate:'tbl_registration',
        urlUpdate:'/admin/updateTable',
        updateFields:UPDATE_FIELDS,
        updateView:UPDATE_VIEW,
        viewFields:VIEW_FIELDS
    }
}    

export default connect(mapStateToProps, null) (withRecords(GetRegistrationMapMap));    
