import React from 'react'
import { connect } from 'react-redux'
import withRecords from 'Components/Table/withRecords'
import GetRegistrationMapMap from './GetRegistrationMapMap'
import backgroundColor from '@material-ui/core/colors/indigo'
import red from '@material-ui/core/colors/red'
import indigo from '@material-ui/core/colors/indigo'
import {PRODUCT_TYPE} from 'Settings/Const'


const GROUP_BY_DEF_ARR=[
    {
        groupByField:'scheduleName', 
        headerFields:['scheduleName'],

        headerProps:{
            open:{
                width:'100vw', 
                paddingTop:10, 
                paddingBottom:10, 
                backgroundColor:backgroundColor[600],
            }, 
            imbalance:{
            }, 
            closed:{
                width:'50vw',
                padding:2, 
                backgroundColor:backgroundColor[500], 
            }
        }    
    },    
    {
        groupByField:'groupByProductId',
        headerFields:['name',  'city', 'dayOfWeek', 'startTime', 'teachers', 'startDate'], 
        headerSortBy:'sequenceNumber',
        listSortBy:['firstName','lastName'],
        headerProps:{
            open:{
                width:'100vw', 
                paddingTop:10, 
                paddingBottom:10, 
                backgroundColor:backgroundColor[900],
            }, 
            imbalance:{
                backgroundColor:red[800],
            }, 
            closed:{
                width:'80vw',
                padding:2, 
                backgroundColor:backgroundColor[800], 
            }
        }    
    },    
];

const VIEW_FIELDS=['firstName', 'lastName', 'email', 'phone', 'leader', 'status', 'firstNamePartner', 'lastNamePartner', 'emailPartner', 'danceSite', 'regDate', 'newsletter', 'shallPay','havePaid', 'productId', 'orderId'];
const UPDATE_FIELDS = ['firstName', 'lastName', 'email', 'phone', 'leader', 'status', 'firstNamePartner', 'lastNamePartner', 'emailPartner', 'danceSite', 'phonePartner', 'shallPay', 'havePaid', 'productId']        
const UPDATE_VIEW = ['firstName', 'lastName', 'email', 'phone', 'leader', 'status', 'firstNamePartner', 'lastNamePartner', 'emailPartner', 'danceSite', 'phonePartner', 'productId']
const mapStateToProps = (state) => {
    return {
        username: state.user.username,
        password: state.user.password,
        language: state.language,
        url:'/admin/getRegistration?productType=course' + '&language=' + state.language,
        groupByDefArr:GROUP_BY_DEF_ARR,
        viewFields:VIEW_FIELDS,
        updateFields:UPDATE_FIELDS,
        updateView:UPDATE_VIEW,
        tableUpdate:'tbl_registration',
        urlUpdate:'/admin/updateTable',
        urlUpdateAll:'/admin/updateTableAll',
    }
}    

export default connect( 
    mapStateToProps,
) (withRecords(GetRegistrationMapMap));    
