import React, {Component} from 'react';
import { connect } from 'react-redux';
import withRecords from 'Components/Table/withRecords';
import GetRegistrationMapMap from './GetRegistrationMapMap'
import backgroundColor from '@material-ui/core/colors/blueGrey'
import {PRODUCT_TYPE} from 'Settings/Const'

const GROUP_BY_DEF_ARR=[
    {
        groupByField:'eventType', 
        headerFields:['eventType'],
        headerProps:{
            open:{
                width:'100vw', 
                backgroundColor:backgroundColor[600]
            }, 
            closed:{
                width:'50vw',
                backgroundColor:backgroundColor[500], 
            }
        },    
    },    
    {
        groupByField:'dateRange', 
        headerFields:['dateRange'],
        headerProps:{
            open:{
                width:'100vw', 
                backgroundColor:backgroundColor[600]
            }, 
            closed:{
                width:'80vw',
                backgroundColor:backgroundColor[500], 
            }
        }    
    },    
    {
        groupByField:'product', 
        headerFields:['product'],

        headerProps:{
            open:{
                width:'100vw', 
                backgroundColor:backgroundColor[900]
            }, 
            closed:{
                width:'80vw',
                backgroundColor:backgroundColor[800], 
            }
        }    
    },    
] 

const VIEW_FIELDS=['id', 'firstName', 'lastName', 'email', 'phone', 'firstNamePartner', 'lastNamePartner', 'emailPartner', 'phonePartner']
const UPDATE_FIELDS=['firstNamePartner', 'lastNamePartner','emailPartner' , 'phonePartner']
const UPDATE_VIEW=['firstNamePartner', 'lastNamePartner','emailPartner' , 'phonePartner']


const mapStateToProps = (state) => {
    return {
        username: state.user.username,
        password: state.user.password,
        language: state.language,
        url:'/admin/getRegistrationFestivalByProduct?language=' + state.language,
        groupByDefArr:GROUP_BY_DEF_ARR,
        tableUpdate:'tbl_registration_festival_product',
        urlUpdate:'/admin/updateTable',
        viewFields:VIEW_FIELDS,
        updateFields:UPDATE_FIELDS,
        updateView:UPDATE_VIEW,
    }
}    

export default connect( 
    mapStateToProps,
) (withRecords(GetRegistrationMapMap));    
