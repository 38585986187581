import React from 'react';
import { withRouter } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { Link } from 'react-router-dom'
import {connect } from 'react-redux'
import tkColors from 'Settings/tkColors'
import {EVENT_STATUS} from 'Settings/Const'
import { EventBusy } from '@material-ui/icons';


const TEXTS = {
    CLOSE:{
        SV:'Stäng',
        ES:'Cerrer',
        EN:'Close',
    },
    LOCATION:{
      SV:'Plats',
      ES:'Locacion',
      EN:'Location',
    },
    REG:{
      SV:'Anmälan',
      ES:'Registrar',
      EN:'Register',
    },
    FULL:{
      SV:'Fullbokad',
      ES:'Fully booked',
      EN:'Fully booked',
    },
    MAX_DANCERS:{
      SV:'Max antal dansare',
      ES:'Max allowed dancers',
      EN:'Max allowed dancers',
    },
    REMAINING:{
      LEADER:{
        SV:'Återstående antal lediga platser för förare',
        ES:'Available space for leaders',
        EN:'Available space for leaders',
      }, 
      FOLLOWER:{
        SV:'Återstående antal lediga pl:atser för följare',
        ES:'Available space for followers',
        EN:'Available space for followers',
      }
    },     
    EVENT_STATUS:{
      OPEN:{
        SV:'Platser kvar för både förare och följare.',
        EN:'Space available for both leaders and followers',
        ES:'Space available for both leaders and followers',
      }, 
      FULL:{
        SV:'Dansen är fullbokad. Kontakta Tangkompaniet för eventuella återbud.',
        EN:'No space available. Contact Tangokompaniet and check for cancellations',
        ES:'No space available. Contact Tangokompaniet and check for cancellations',
      }, 
      LEADERS_FULL:{
        SV:'Fullbokat för förare, bara platser kvar till följare',
        EN:'Fully booked for leaders, only space availabile for followers',
        ES:'Fully booked for leaders, only space availabile for followers',
      }, 
      LEADERS_OVERFLOW:{
        SV:'Överskott på förare (+3), vänta och se om fler följare bokar sig',
        EN:'Overflow of leaders (3), wait and see if more followers books',
        ES:'Overflow of leaders (3), wait and see if more followers books',
      }, 
      FOLLOWERS_FULL:{
        SV:'Fullbokat för följare, bara platser kvar till förare',
        EN:'Fully booked for followers, only space availabile for leaders',
        ES:'Fully booked for followers, only space availabile for leaders',
      },
      FOLLOWERS_OVERFLOW:{
        SV:'Överskott på följare (+3), vänta och se om fler förare bokar sig',
        EN:'Overflow of followers (3), wait and see if more förare bookar sig',
        ES:'Overflow of followers (3), wait and see if more förare bookar sig',
      },
    },     
    NO_LIMITATIONS:{
      SV:'Ingen begränsning på antalet dansare',
      EN:'No limitations on number of dancers',
      ES:'No limitations on number of dancers',
  }
}  

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AlertDialogSlide(props) {
  const {open, setOpen, event, language, addRegistration} = props  
  const useRegistrationButton = event.title?event.title.toLowerCase().indexOf('anmäl')!==-1:false
  const bookable = (event.avaPar?event.avaPar > 0:false) || (event.avaInd?event.avaInd > 0:false) || (event.avaPar===undefined && event.avaInd === undefined)
  const title=event.weekday + ' ' + event.startDate + ' ' + event.startTime + ' '
  const handleClose = () => {
    setOpen(false);
  }
  const handleRegistration = event => { 
    const history = props.history;
    const path = '/registration/' +  event.productType +  '/' + event.productId + '/' + event.eventStatus 
    history.push(path);
  }    
  
  const renderBody = () =>  {
    const location = event.location
    const desc = event.description?event.description:'' //.split('MAX_PAR')[0]
    const color = event.eventStatus === EVENT_STATUS.OPEN?'green':event.eventStatus === EVENT_STATUS.FULL?'red':'deepOrange'
    return(
      <div>
      <h3>{location}</h3>
        {useRegistrationButton?
          event.maxPar || event.maxInd?
                <div style={{color}}>
                    <p>
                        {TEXTS.EVENT_STATUS[event.eventStatus][language]}
                    </p>  
                    {event.eventStatus !== EVENT_STATUS.FULL?
                      <p>
                          {TEXTS.MAX_DANCERS[language]} = {event.maxInd?event.maxInd:event.maxPar?event.maxPar*2:'No value'}
                          <br/>
                          {TEXTS.REMAINING.LEADER[language]} =  {event.avaLeader}
                          <br />
                          {TEXTS.REMAINING.FOLLOWER[language]} = {event.avaFollower}
                      </p>
                    :null}
                </div>  
          :
                <div style={{color:'green'}}>
                    {TEXTS.NO_LIMITATIONS[language]}
                </div>      
          :null
        }
        <div style={{color:tkColors.black, maxHeight:'calc(100vw - 210px)', overflowX:'hidden', overflowY:'auto'}} dangerouslySetInnerHTML={{__html: desc}} />
      </div>
    )
  }
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        color="primary"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
              {renderBody()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={handleClose} color="primary">
             {TEXTS.CLOSE[language]}
          </Button>
          {useRegistrationButton?
                bookable?
                    <Button variant="outlined" color="primary" onClick={()=>handleRegistration(event)}>
                      {TEXTS.REG[language]} 
                    </Button>
                :    
                    <Button variant="outlined" color="primary" disabled >
                      {TEXTS.FULL[language]}
                    </Button>
                    
          :null}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
    return {
        language: state.language,
    }    
}    
 
export default connect(mapStateToProps)(withRouter(AlertDialogSlide))
  
