import React from 'react';
import Inventory from 'scenes/Shop/Products/Inventory'
import GetOrder from 'scenes/School/Registration/GetOrder'
import GetRegistrationBySchedule from 'scenes/School/Registration/GetRegistrationBySchedule'
import GetRegistrationByCourse from 'scenes/School/Registration/GetRegistrationByCourse'
import GetRegistrationByTeacher from 'scenes/School/Registration/GetRegistrationByTeacher'
import GetRegistrationSocial from 'scenes/School/Registration/GetRegistrationSocial'
import GetRegistrationMarathon from 'scenes/School/Registration/GetRegistrationMarathon'
import GetRegistrationFestival from 'scenes/School/Registration/GetRegistrationFestival'
import GetRegistrationFestivalByProduct from 'scenes/School/Registration/GetRegistrationFestivalByProduct'
import GetRegistrationFestivalTotal from 'scenes/School/Registration/GetRegistrationFestivalTotal'
import GetRegistrationHistory from 'scenes/School/Registration/GetRegistrationHistory'
import ScheduleChange from 'scenes/Schedule/ScheduleChange'
import CourseNotes from 'scenes/School/Registration/CourseNotes'
import Presence from 'scenes/School/Registration/Presence'
import PresenceHistory from 'scenes/School/Registration/PresenceHistory'
import PresenceHistory2 from 'scenes/School/Registration/PresenceHistory2'
import CreateCourse from 'scenes/Schedule/CreateCourse'
import CreatePackage from 'scenes/Schedule/CreatePackage'
import CreateWorkshop from 'scenes/Schedule/CreateWorkshop'
import Images from 'scenes/Shop/Products/Images'
import ImagesSubdir from 'scenes/Shop/Products/ImagesSubdir'

export const VIEW = {
    IMAGES:'IMAGES',
    IMAGES_SUBDIR:'IMAGES_SUBDIR',
    INVENTORY:'INVENTORY',
    ORDER:'ORDER',
    REG:'REG',
    REG_BY_SCHEDULE:'REG_BY_SCHEDULE',
    REG_BY_CLASS:'REG_BY_CLASS',
    REG_BY_TEACHER:'REG_BY_TEACHER',
    REG_EVENT_BY_MARATHON:'REG_EVENT_BY_MARATHON',
    REG_EVENT_BY_WORKSHOP:'REG_EVENT_BY_WORKSHOP',
    REG_EVENT_BY_PRODUCT:'REG_EVENT_BY_PRODUCT',
    REG_EVENT_TOTAL:'REG_EVENT_TOTAL',
    REG_EVENT_BY_NAME:'REG_EVENT_BY_NAME',
    REG_MARATHON:'REG_MARATHON',
    REG_SOCIAL:'REG_SOCIAL',
    PRESENCE:'PRESENCE',
    PRESENCE_HISTORY:'PRESENCE_HISTORY',
    PRESENCE_HISTORY2:'PRESENCE_HISTORY2',
    COURSE_NOTES:'COURSE_NOTES',
    CREATE_SCHEDULE:'CREATE_SCHEDULE',
    CREATE_COURSE:'CREATE_COURSE',
    CREATE_WORKSHOP:'CREATE_WORKSHOP',
    CREATE_PACKAGE:'CREATE_PACKAGE',
    CREATE_SCHEDULE_DEF:'CREATE_SCHEDULE_DEF',
    CREATE_MARATHON:'CREATE_MARATHON',
    CREATE_SOCIAL_DANCING:'CREATE_SOCIAL_DANCING',
    SCHEDULE_CHANGE:'SCHEDULE_CHANGE',
    SCHEDULE_CHANGE_NEW:'SCHEDULE_CHANGE_NEW',
    TABLES:'TABLES'
}
    

export const regList = [   
    { 
        key:VIEW.REG_SOCIAL,
        titleSV:'Anmälan till social dans', 
        titleEN:'Registrations social',  
        titleES:'Aplicación social', 
        component:React.cloneElement(<GetRegistrationSocial />),

    },
    { 
        key:VIEW.REG_BY_SCHEDULE,
        titleSV:'Anmälan (per schema)', 
        titleEN:'Registrations (by schedule)',  
        titleES:'Aplicación supuesto (argrupados per curso)', 
        component:React.cloneElement(<GetRegistrationBySchedule />),
    },
    { 
        key:VIEW.REG_BY_CLASS,
        titleSV:'Anmälan (per kurs)', 
        titleEN:'Registrations (by course)',  
        titleES:'Aplicación supuesto (argrupados per curso)', 
        component:React.cloneElement(<GetRegistrationByCourse />),
    },
    { 
        key:VIEW.REG_BY_TEACHER,
        titleSV:'Anmälan (per lärarpar)', 
        titleEN:'Registrations (by teacher)', 
        titleES:'Aplicación supuesto (argrupar per profesor)', 
        component:React.cloneElement(<GetRegistrationByTeacher />),
    },
    { 
        key:VIEW.REG_EVENT_BY_MARATHON,
        titleSV:'Anmälan Maraton', 
        titleEN:'Registration MARATHON',  
        titleES:'Aplicación event', 
        component:React.cloneElement(<GetRegistrationMarathon />),
    },
    { 
        key:VIEW.REG_EVENT_BY_WORKSHOP,
        titleSV:'Anmälan Festival', 
        titleEN:'Registration Festival',  
        titleES:'Aplicación festival', 
        component:React.cloneElement(<GetRegistrationFestival />),
    },
    { 
        key:VIEW.REG_EVENT_BY_PRODUCT,
        titleSV:'Anmälan Festival per Product', 
        titleEN:'Registration Festival by product',  
        titleES:'Aplicación festival en product', 
        component:React.cloneElement(<GetRegistrationFestivalByProduct />),
    },
    { 
        key:VIEW.REG_EVENT_TOTAL,
        titleSV:'Anmälan Festival Totalt', 
        titleEN:'Registration Festival Totalt',  
        titleES:'Aplicación festival total', 
        component:React.cloneElement(<GetRegistrationFestivalTotal />),
    },
    { 
        key:VIEW.REG_EVENT_BY_NAME,
        titleSV:'Historik', 
        titleEN:'History', 
        titleES:'History', 
        component:React.cloneElement(<GetRegistrationHistory />),
    },
    { 
        key:VIEW.PRESENCE,
        titleSV:'Närvaro - registering + kommentar', 
        titleEN:'Precense - register + comment',  
        titleES:'Precensia - registrarse + commentario', 
        component:React.cloneElement(<Presence />),
    },
    { 
        key:VIEW.PRESENCE_HISTORY,
        titleSV:'Närvaro - historik', 
        titleEN:'Presence - history',  
        titleES:'Histora del presencia', 
        component:React.cloneElement(<PresenceHistory />),
    },
    { 
        key:VIEW.PRESENCE_HISTORY2,
        titleSV:'Närvaro/Frånvaro - historik', 
        titleEN:'Presence/Absence - history',  
        titleES:'Historial de precencia/ausencia', 
        component:React.cloneElement(<PresenceHistory2 />),
    },
    { 
        key:VIEW.COURSE_NOTES,
        titleSV:'Lärarens noteringar', 
        titleEN:'Teachers notes',  
        titleES:'Las notas del maestro', 
        component:React.cloneElement(<CourseNotes />),
    },
]
export const scheduleList = [   
    { 
        key:VIEW.CREATE_COURSE,
        titleSV:'Skapa kursschema', 
        titleEN:'Create course schedule', 
        titleES:'Horario de curso', 
        component:React.cloneElement(<CreateCourse />),
    },
    { 
        key:VIEW.CREATE_WORKSHOP,
        titleSV:'Skapa workshops', 
        titleEN:'Create workshop schedule', 
        titleES:'Horario de taller', 
        component:React.cloneElement(<CreateWorkshop />),
    },
    { 
        key:VIEW.CREATE_PACKAGE,
        titleSV:'Skapa Festivalpaket', 
        titleEN:'Create package events', 
        titleES:'Package for event', 
        component:React.cloneElement(<CreatePackage />),
    },
    { 
        key:VIEW.SCHEDULE_CHANGE_NEW,
        titleSV:'Omläggning av schema', 
        titleEN:'Reschedule schema', 
        titleES:'Cambio de horario', 
        component:React.cloneElement(<ScheduleChange />),  
    },
]    

export const orderList = [   
    { 
        key:VIEW.ORDER,
        titleSV:'Beställning', 
        titleEN:'Order', 
        titleES:'Ordero', 
        component:React.cloneElement(<GetOrder />),
    },
    { 
        key:VIEW.IMAGES,
        titleSV:'Skoaffär - bilder i huvudmap', 
        titleEN:'Shoe store - images i man dir', 
        titleES:'Zapatos - fotos de zapatos main-dir', 
        component:React.cloneElement(<Images />),
    },
    { 
        key:VIEW.IMAGES_SUBDIR,
        titleSV:'Skoaffär - bilder i undermapp', 
        titleEN:'Shoe store - img in sub-dir', 
        titleES:'Zapatos - fotos de subdirectorio', 
        component:React.cloneElement(<ImagesSubdir />),
    },
    { 
        key:VIEW.INVENTORY,
        titleSV:'Skoaffär - inventory', 
        titleEN:'Shoe stor - inventory', 
        titleES:'Zapatos - inventario', 
        component:React.cloneElement(<Inventory />),
    },
]  

export const tableList = [
    { 
        name:'tbl_phonebook',
        titleSV:'Telefonbok', 
        titleEN:'Phonebook', 
        titleES:'Directorio telfónico', 
        sortBy:['firstName', 'lastName'],
        button:{name:'Update Phonebook', link:'/updatePhonebook'},
    },
    { 
        name:'tbl_teacher',
        mandatoryColumns:['workshopId', 'nameEN'],
        titleSV:'Lärare', 
        titleEN:'Teacher', 
        titleES:'Maestro', 
    },
    { 
        name:'tbl_site',
        titleSV:'Danslokal', 
        titleEN:'Place', 
        titleES:'Sitio', 
    },
    {
        name:'tbl_course_def',
        sortBy:['sequenceNumber'],
        titleSV:'Kurs definition', 
        titleEN:'Course definitions', 
        titleES:'Cursos programados', 
    },
    {
        name:'tbl_course',
        sortBy:['productId'],
        titleSV:'Kurs schema', 
        titleEN:'Course schedule', 
        titleES:'Hourario de los cursos', 
    },
    { 
        name:'tbl_price_group',
        titleSV:'Prisgrupp', 
        titleEN:'Pricegroup', 
        titleES:'Grupo de precios', 
    },
    {
        name:'tbl_schedule_def',
        titleSV:'Event schema', 
        titleEN:'Event schedule', 
        titleES:'Event horario', 
    },
    {
        name:'v_marathon_names',
        titleSV:'Maraton namnlista', 
        titleEN:'Marathon namelist', 
        titleES:'Nombre en marathon', 
        url:'/admin/tktableWithoutId?tableName=v_marathon_names'

    },
    {
        name:'tbl_course_type',
        titleSV:'Kursgruppering', 
        titleEN:'Course grouping', 
        titleES:'Agroupacion de courses', 
    },
    {
        name:'tbl_text',
        titleSV:'Texter (tbl_text)', 
        titleEN:'Text (tbl_text)', 
        titleES:'Text (tbl_text)', 
    },
    /*
    { 
        name:'tbl_order',
        titleSV:'Beställning', 
        titleES:'Orden', 
        titleEN:'Order', 
        url:'/admin/tktableWithoutId?tableName=tbl_order'
    },
    { 
        name:'tbl_order_product',
        titleSV:'Varor per beställning', 
        titleES:'Bienes ordenados', 
        titleEN:'Products ordered', 
    },
    */
]
