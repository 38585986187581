import React, {Component} from 'react';
import { connect } from 'react-redux'
import tkColors, {boxShadowValue} from 'Settings/tkColors';
import groupBy from 'functions/groupBy';
import RenderEventRegHeader from './RenderEventRegHeader'
import RenderEventRegLine from './RenderEventRegLine'
import config from 'Settings/config';
import postData from 'functions/postData'
import fetchList from 'functions/fetchList'
import FormTemplate from 'Components/formTemplate'
import {LANGUAGE_EN} from 'redux/actions/actionsLanguage'
import {PRODUCT_TYPE} from 'Settings/Const'



const apiBaseUrl=config[process.env.NODE_ENV].apiBaseUrl;
const FORM_FIELDS_URL = apiBaseUrl + "/formFields"
const SCHEDULE_EVENT_URL = apiBaseUrl + "/scheduleEvent"
const SCHEDULE_WORKSHOP_URL = apiBaseUrl + '/scheduleWorkshop?language=EN'
const PACKAGE_URL = apiBaseUrl + '/packageDef?language=EN'
const CREATE_REG_URL = apiBaseUrl + '/createRegistrationFestival'
const imageUrlFunc = replyImage => apiBaseUrl + '/' + replyImage
/*
const imageExistsFunc = (imageUrl) => {
    var http = new XMLHttpRequest();
    http.open('HEAD', imageUrl, false);
    http.send();
    return http.status != 404;
}
*/
const STEPS = {
    INITIAL:0,
    FAILED:1, 
    SUCCESS:2
}

const STATUS={
    MISSING:'MISSING',
    NOT_OPEN_YET:'NOT_OPEN_YET',
    FINISHED:'FINISHED',
}

const TEXTS = {
    PACKAGES:{
        SV:'Paket',
        EN:'Packages',
        ES:'Packages'
    }
}

let styles = {
    clear:{
        clear:'both',
        fontWeight:600,
    },
    schema:color => ({
        display:'inline-block',
        //clear:'both',
        marginTop:10,
        marginBottom:10,
        paddingLeft:30,
        paddingRight:30,
        paddingBottom:30,
        fontWeight:600,
        color,
        //border:'3px solid ' + color, 
        boxShadow:'0 13px 27px -5px ' + color,
    }),
    workshops:color => ({
        clear:'both',
        marginTop:10,
        marginBottom:10,
        borderTop:'2px solid ' + color,
    }),
    packages: {
        width:'100%',
        display:'inline-block', 
    },
    forms: color => ({
        borderTop:'2px solid ' + color,
    }),
    table: {
        flex:1,
        marginTop:20,
    },
    thead: {
        fontSize:'small', 
    },
    tbody: {
     //   border:2, 
     //   cellpadding:20,
    },
    trhead: {
        height:10,
        verticalAlign:'top',
        fontSize: 14,
        //borderBottom:'1px solid',
        //borderColor:'green',
        //backgroundColor:'tkColors.background',
        // backgroundColor:'transparent',
    },
    tr: {
        height:20,
        verticalAlign:'top',
        fontSize: 14,
        //borderBottom:'1px solid',
        //borderColor:'green',
        //backgroundColor:'tkColors.background',
        // backgroundColor:'transparent',
    },
    tdTitle: {
        verticalAlign:'top',
        fontSize:14,
        fontWeight: 'bold',
    },
    th: {
        verticalAlign:'bottom',
        fontSize:12,
        fontWeight: 'lighter',
    },
    td: {
        verticalAlign:'top',
        minWidth:28,
    },
    border: color => ({
        borderColor:color,
        boxShadow:boxShadowValue(color)
    }),
    radio: color =>({
        width:20,
        border:'5px solid ' + color,
        color:color,
    }),
    name: color =>({
        padding:5,
        fontSize:14,
        textAlign:'left',
        border:'1px dotted ' + color,
        borderCollapse:'collapse',
    }),
};

class Schedule extends Component {
    constructor() {
        super();
        this.state = {
            name:undefined, 
            eventType:undefined,
            toggleText:false, textId:'', 
            color:'brown',
            multiplePartners:false,
            packageList:[], 
            workshopList:[], 
            productList:[],
            workshopPartners:{},
            packageId:undefined, 
            orderId:0,
            packageName:undefined, 
            amount:0,
            person:undefined,
            step:STEPS.INITIAL,
            status:STATUS.MISSING,
            dateRange:undefined,
            openRegDate:undefined,
            openRegTime:undefined,
            startDate:undefined, 
            startTime:undefined, 
            year:undefined, 
            emailResponsible:undefined,
            imageUrl:undefined,
            replyImage:undefined,
            formFields:[],
        };
        this.toggleProduct = this.toggleProduct.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleRegistration = this.handleRegistration.bind(this)
        this.handleReply = this.handleReply.bind(this)
    }

    componentDidMount () {
        const eventTypeParams=this.props.match?this.props.match.params?this.props.match.params.eventType:undefined:undefined
        const eventTypeProps = this.props.eventType?this.props.eventType:undefined
        const eventType = eventTypeProps?eventTypeProps:eventTypeParams?eventTypeParams:undefined
        console.log('eventType:', eventType)  
        this.setState({eventType, color:this.props.globalStyle.color?this.props.globalStyle.color:'brown'});         

        fetchList('', '', SCHEDULE_EVENT_URL + '?eventType=' + eventType, 
            result =>
            {
                const schedule = Array.isArray(result)?result[0]:result
                if (schedule) {
                    
                    this.setState({
                        name:schedule.name,
                        status:schedule.status, 
                        dateRange:schedule.dateRange, 
                        openRegDate:schedule.openRegDate, 
                        openRegTime:schedule.openRegTime, 
                        startDate:schedule.startDate, 
                        startTime:schedule.startTime, 
                        year:schedule.year, 
                        emailResponsible:schedule.emailResponsible,
                        replyImage:schedule.replyImage,
                        imageUrl:imageUrlFunc(schedule.replyImage),
                    }) 
                }
                console.log(SCHEDULE_EVENT_URL, ' schedule:', schedule)
            }
        )    

        fetchList('', '', FORM_FIELDS_URL + '?formName=' + eventType, 
            result =>
            {
                if (result) {
                    //alert(JSON.stringify(result))
                    this.setState({
                        formFields:result
                    }) 
                }
                console.log(SCHEDULE_EVENT_URL, ' result:', result)
            }
        )    


        fetchList('','', PACKAGE_URL, 
            list=>{ 
                // alert(JSON.stringify(list))
                const packageList = list.filter(it => eventType?eventType===it.eventType:true)                   
                if (packageList?packageList.length > 0:false) {
                    if (packageList?packageList.length > 0:false) {
                        this.setState({packageList})
                    }    
                }
            }        
        )
        fetchList('','', SCHEDULE_WORKSHOP_URL, 
            list=>{
                const workshopList = list.filter(it => eventType?eventType===it.eventType:true)
                if (workshopList?workshopList.length > 0:false) {
                    this.setState({
                        workshopList: workshopList.map(it=>({...it, scheduleKey:it.eventType+'_'+it.year})),
                    })
                }     
            }
        )
    }


    toggleProduct = (e) => {
        let productList = undefined
        let workshopPartners = undefined
        if (e.target.checked) {
            /* Add entry if it does not exists */
            productList = [...this.state.productList, e.target.name]
            workshopPartners = this.state.workshopPartners
        } else {
            /* Remove entry if it exists */
            productList = this.state.productList.filter(pid => pid !== e.target.name)
            workshopPartners = this.state.workshopPartners
            delete(workshopPartners[e.target.name])
        }   
        // alert(JSON.stringify(productList) + ' ' + JSON.stringify(workshopPartners))
        this.setState({productList, workshopPartners})
    };

    handleWorkshopPartner = (workshopId, e) => {
        const value = this.state.workshopPartners[workshopId]?{...this.state.workshopPartners[workshopId], [e.target.name]:e.target.value}:{[e.target.name]:e.target.value}
        const workshopPartners = {...this.state.workshopPartners, [workshopId]:value}
        this.setState({workshopPartners})
    };

    workshopPartnerStr() {
        let strWorkshopPartners = ""
        Object.entries(this.state.workshopPartners).map(wsp => {
            strWorkshopPartners += '(' + wsp[0] + ':';
            Object.entries(wsp[1]).map(pa => {
               strWorkshopPartners += " " + pa[1]
            })
            strWorkshopPartners += ') ' + wsp[0];
        })    
        return strWorkshopPartners
    }
    
    renderPackages() {
        return(
        this.state.packageList.length > 0?   
            <>
                <table style={styles.table}>
                    <thead style={styles.thead}>
                        <th style={styles.th}>Select</th>
                        <th style={styles.th}>Package</th>
                    </thead>
                    <tbody styles={styles.tbody}>
                        {this.state.packageList.map(pkg => 
                            <tr style={styles.name(this.state.color)}>
                            <td> 
                                <input type="checkbox" name={pkg.name} onChange={this.toggleProduct}/>
                            </td>    
                            <td >
                                {pkg.name} 
                            </td>
                        </tr>
                    )}    
                    </tbody>
                </table>           
            </>
        :
            null    
        )
    }
    
    renderWorkshopsByDay = (workshops) => (
        <table style={{...styles.table}}>
            <RenderEventRegHeader startDate={workshops[0].startDate} dayOfWeek={workshops[0].dayOfWeek} language = {this.props.language} />
            <tbody style={styles.tbody}>
                {workshops.filter(it=>it.active != 0).map(ws => 
                    <RenderEventRegLine 
                        event={ws} 
                        checked={this.state.productList.includes(ws.workshopId)}
                        language = {this.props.language} 
                        toggleProduct={this.toggleProduct}
                        multiplePartners={this.state.multiplePartners}
                        handleWorkshopPartner={e=>this.handleWorkshopPartner(ws.workshopId, e)}
                    />
                )}  
            </tbody>
        </table>
    ) 


    // Render all workshops for one scheduleKey
    renderWorkshopsBySchedule = (list) => {
        let dayMap = groupBy(list, it => it.daysUntilStart)
        let days = [...dayMap.keys()].sort((a,b)=> a.startTime - b.startTime)
        console.log('productList', this.state.productList)
        return (
        <div style={styles.workshops()}>    
                {days.map(day => (
                    this.renderWorkshopsByDay(dayMap.get(day))
                ))}
        </div>
        )
    }

    filterList() {
        const list = this.state.workshopList
        const eventType = this.props.eventType?this.props.eventType
            :this.props.match.eventType?this.props.match.eventType
            :undefined     
        const dateRange = this.props.dateRange?this.props.dateRange
            :this.props.match.dateRange?this.props.match.dateRange
            :undefined     
        if (eventType !== undefined) {
            console.log('filterList before:', list, 'eventType:', eventType)           
            list = this.state.workshopList.filter(it => it.eventType === eventType && dateRange?it.dateRange===dateRange:true)
        }    
        console.log('filterList after:', list, eventType)           
        return list    
    }  

    handleReply (data) {
        //alert(JSON.stringify(data) + 'data.status=' + data.status + ' orderId=' + data.orderId)
        this.setState({step:data.status==='OK'?STEPS.SUCCESS:STEPS.FAILED, orderId:data.orderId})
    }    
   
    handleRegistration= (regExtended) => {
        // alert('handleRegistration:' + JSON.stringify(regExtended) + '\n' + JSON.stringify(regExtended))
        postData(CREATE_REG_URL, '', '', regExtended, this.handleReply);
    }

    multiplePartnersCheck() {
        let ret = true

        if (this.state.multiplePartners) {
            const workshopList = this.state.workshopList.filter(it => this.state.productList.includes(it.workshopId))
            workshopList.forEach(wp => {
                const workshopId = wp.workshopId
                const workshopPartners = this.state.workshopPartners[workshopId]
                if (workshopPartners === undefined) {
                    alert('Please fill in partner info missing for workshop ' + workshopId)
                    ret = false
                } else if (workshopPartners.firstNamePartner === undefined) {
                    alert('Plaese fill in partners first name for workshop' + workshopId)
                    ret = false
                } else if (workshopPartners.lastNamePartner === undefined) {
                    alert('Plaese fill in partners last name for workshop' + workshopId)
                    ret = false
                } else if (workshopPartners.emailPartner === undefined) {
                    alert('Plaese fill in partners email for workshop' + workshopId)
                    ret = false
                }
            })
        }    
        return ret
    }

    notSamePartnerCheck(reg) {
        if (reg.emailPartner !== undefined && reg.email !== undefined) {
            if (reg.email.toLowerCase().trim() === reg.emailPartner.toLowerCase().trim()) {
                alert('Warning: You cannot use same email for partner as for yourself')
                return false
            } 
        } 
        return true
    }   

    extendedProductList(reg) {
        return this.state.productList.map(it => {
            const workshopPartners = this.state.workshopPartners[it]
            const productType=this.state.workshopList.find(ws => ws.workshopId === it)?PRODUCT_TYPE.WORKSHOP:PRODUCT_TYPE.PACKAGE
            const firstNamePartner=workshopPartners?workshopPartners.firstNamePartner:reg.firstNamePartner?reg.firstNamePartner:undefined
            const lastNamePartner=workshopPartners?workshopPartners.lastNamePartner:reg.lastNamePartner?reg.lastNamePartner:undefined
            const emailPartner=workshopPartners?workshopPartners.emailPartner:reg.emailPartner?reg.emailPartner:undefined
            const phonePartner=workshopPartners?workshopPartners.phonePartner:reg.phonePartner?reg.phonePartner:undefined
            return (
                {
                    eventType:this.props.eventType, 
                    dateRange:this.state.dateRange,
                    productType, 
                    product:it, 
                    firstName:reg.firstName,
                    lastName:reg.lastName,
                    email:reg.email,    
                    phone:reg.phone,
                    role:reg.role,
                    firstNamePartner, 
                    lastNamePartner, 
                    emailPartner,
                    phonePartner
                }    
            )
        })        
    }

    regExtended(reg) {
        return ({
            ...reg, 
            eventType:this.props.eventType, 
            year:this.state.year, 
            dateRange:this.state.dateRange, 
            emailResponsible:this.state.emailResponsible, 
            // workshopPartners: this.workshopPartnerStr(),
            imageUrl:this.state.imageUrl,
            // productList:this.state.productList,
            extendedProductList:this.extendedProductList(reg)
        })
    }    


    handleSubmit = (e, reg) => {
        e.preventDefault() 
        
        let failed = false

        if (!this.multiplePartnersCheck()) {
            return 
        }
        if (!this.notSamePartnerCheck(reg)) {
            return 
        }
        
        const regExtended = this.regExtended(reg)

        this.handleRegistration(regExtended)
    }

    /* <FormTemplate fields={formFields} handleSubmit={this.handleSubmit}/> */
    renderSchedules () {
        const schemaMap = groupBy(this.state.workshopList, it => it.eventType + it.year)
        const scheduleKeys = [...schemaMap.keys() ];
        const color = this.state.color
        const replyImage = this.state.replyImage
        const imageUrl = this.state.imageUrl
        // let formFields=this.props.formFields.filter(it=>this.state.multiplePartners?it.name.includes('Partner')?false:true:true) 
        return(
            <div style={{color}}>
                {this.state.status===undefined?
                    <h2>There is no schedule defined for eventType = {this.state.eventType} event</h2>
                :this.state.status===STATUS.NOT_OPEN_YET?
                    <h2>Registration opens {this.state.openRegDate} at {this.state.openRegTime} </h2>
                :this.state.status===STATUS.FINISHED?
                    <h2>{this.state.name} has finished</h2>
                :this.state.step===STEPS.INITIAL?
                    <div>
                        {this.state.packageList.length > 0 || scheduleKeys.length > 0?
                            <div style={styles.schema(color)}>
                                {this.state.packageList.length > 0? 
                                    <div style={styles.packages}>
                                        <h3 style={{width:'100%', textAlign:'center'}}>Packages</h3>
                                        {this.renderPackages()}
                                    </div>
                                :
                                    null
                                }    
                                {scheduleKeys.length > 0?
                                    <div style={styles.workshops(color)}>
                                        <h3 style={{width:'100%', textAlign:'center'}}>Workshops and milongas</h3>
                                        <label>
                                        I have different dance partners for the different workshops   
                                        <input type="checkbox" checked={this.state.multiplePartners} onChange={e => this.setState({multiplePartners:e.target.checked?true:false})} />
                                        </label>
                                        {scheduleKeys.map(scheduleKey =>this.renderWorkshopsBySchedule(schemaMap.get(scheduleKey)))}
                                    </div>
                                :
                                    null
                                }
                                {this.state.packageList.length > 0 || scheduleKeys.length > 0?     
                                    <div style={styles.forms(this.state.color)}>
                                        <h3 style={{width:'100%', textAlign:'center'}}>Personal information</h3>
                                        <FormTemplate fields={this.state.formFields} handleSubmit={this.handleSubmit}/>
                                    </div>
                                :
                                    null    
                                }    
                            </div>
                        :
                            null
                        }
                    </div>
                :this.state.step===STEPS.SUCCESS?
                    <div>
                        {replyImage?<img src={imageUrl} width='50%' alt={'Image not found:' + imageUrl} />:null}
                        <h2 >Your registration with order number {this.state.orderId} at Tangokompaniet was successful</h2>
                        <h4 >Please check your mailbox (or spam mailbox) for confirmation of your registration.</h4>
                    </div>
                :this.state.step===STEPS.FAILED?
                    <div style={{clear:'both', display:'inline-block', width:'70%', textAlign:'center', color:'red', fontSize:28}}>
                    <h2 >WARNING: Your registration failed.</h2> 
                    <h4 style={{fontSize:'small'}}>The failed order has number {this.state.orderId}</h4>
                    </div>
                :    
                    <div style={{clear:'both', display:'inline-block', width:'70%', textAlign:'center', color:'orange', fontSize:28}}>
                        <h2 >WARNING: No valid render step for order number {this.state.orderId}</h2>
                    </div>
                }    
            </div>
        )
    }            

    // Render 

    render() {   
        return(this.renderSchedules());  
    }
}

const mapStateToProps = (state) => {
    return {
        language: LANGUAGE_EN, // state.language,
        globalStyle: state.style,
    }
}    

export default connect( 
    mapStateToProps
) (Schedule);    
