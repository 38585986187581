import pink from '@material-ui/core/colors/pink'
import green from '@material-ui/core/colors/green'

const img1=require('images/homepage/start_nude_1x_1.png');
const img2=require('images/homepage/start_nude_1x_2.png');
const img3=require('images/homepage/start_nude_1x_3.png');
const img4=require('images/homepage/start_nude_1x_4.png');

const background = 'linear-gradient(45deg, #590029 0%, #D1188B 100%)'

export const defaultMenuList = [
    {
            label:'Start',
            text:'Here the text and pictures shall be put',
            style:{
                // backgroundImage:`url(${img3})`,
                // backgroundRepeat:'no-repeat',
                background,
            },
    },
    {
            label:'Schedule',
            text:'Here the schedule shall be inserted ...',
            style:{
                // backgroundImage:`url(${img3})`,
                // backgroundRepeat:'no-repeat',
                background,
            },
    },
    {
        label:'Teachers',
        text:'Here the price information shall be inserted ...',
        style:{
            // backgroundImage:`url(${img3})`,
            // backgroundRepeat:'no-repeat',
            background,
        },
    },    
    {
            label:'Prices',
            text:'Here the price information shall be inserted ...',
            style:{
                // backgroundImage:`url(${img3})`,
                // backgroundRepeat:'no-repeat',
                background,
            },
    },
    {
            label:'Register',
            text:'Here the registration information shall be inserted ...',
            style:{
                // backgroundImage:`url(${img3})`,
                // backgroundRepeat:'no-repeat',
                background,
            },
    },
    {
            label:'Venues',
            link:'/item4',
            text:'Here the venue information shall be inserted ...',
            style:{
                // backgroundImage:`url(${img3})`,
                // backgroundRepeat:'no-repeat',
                background,
            },
    },
    {
        label:'Contact Us',
        text:'Here the contact information shall be added ...',
        style:{
                // backgroundImage:`url(${img3})`,
                // backgroundRepeat:'no-repeat',
                background,
        },
}
]
    
