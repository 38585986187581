import React, {Component} from 'react';
import { withBreakpoints } from 'react-breakpoints'
import { connect } from 'react-redux'
import tkColors from 'Settings/tkColors';
import TextShow from 'Components/Text/TextShow';
import Button from 'Components/Button';
import { setScheduleList } from 'redux/actions/actionsSchedule'
import ShoppingCartButton from 'scenes/Shop/ShoppingCartButton';
import withListFromStore from 'Components/Table/withListFromStore';
import {LANGUAGE_SV, LANGUAGE_ES} from 'redux/actions/actionsLanguage'
import groupBy from 'functions/groupBy';
import RenderCoHeader from './RenderCoHeader'
import RenderCoRegHeader from './RenderCoRegHeader'
import RenderCoRegLine from './RenderCoRegLine'
import CircularProgressTerminate from 'Components/CircularProgressTerminate'

const TEXTS = {
    NO_SCHEMA: {
        SV:'Kursschema är ännu ej inlagda på denna sida',
        EN:'Course schedule is not yet posted on this page',
        ES:'El horario del curso aún no está publicado en esta página',
    }
}

const styles = {
    root:{
        textAlign:'center', 
    },
    scheduleNoBorder:{
        display:'flex',
        flexDirection:'row',
        flexWrap:'wrap',
        justifyContent:'center',    
        alignItems:'top',
        width:'100%',
    },
    schedule:{
        background: 'linear-gradient(-45deg, #2ae88a 0%, #08aeea 100%)',
        display:'flex',
        flexDirection:'column',
        flexWrap:'wrap',
        alighItems:'top',    
        width:'100%',
    
        //marginTop:20,
        //marginBottom:40,
        border:'1px solid',
        borderRadius:16,
        color:tkColors.Purple.Light,
        borderColor:tkColors.Purple.Light,
        boxShadow:'0 13px 27px -5px ' + tkColors.Purple.Light,

    },
    course:{
        maxWidth:550,
        marginBottom:12,
        marginLeft:8,
        marginRight:8,
        marginTop:12,
        background: 'linear-gradient(45deg, #81185B 0%,  #610a41 100%)',
        color:'#f6d8e6',
        border:'0.001px solid',
        boxShadow:'0 13px 27px -5px ' + tkColors.Purple.Light,

        // boxShadow:'0px 13px 27px -5px rgba(50, 50, 93, 0.25), 0px 8px 16px -8px rgba(0, 0, 0, 0.3)',        
        borderColor:tkColors.Purple.Light,
        borderRadius:12,
    },
    container:{
        marginLeft:'auto',
        margihRight:'auto',
        display:'flex', 
        flexDirection:'column'
    },
    button:{
        size:'small',
        marginLeft:'auto',
        marginRight:'auto',
    },
    text:{
        maxWidth:1000,
        textAlign:'left',
        marginLeft:'auto',
        marginRight:'auto',
    },
};

class ScheduleCourses extends Component {
    constructor() {
        super();
        this.state = {toggleText:false, textId:'', color:tkColors.Purple.Light};
    }

    componentDidMount () {
        this.setState({color:this.props.style.color})
    }

    handleDesc(textId) {
      this.setState({toggleText:!this.state.toggleText, textId})
    }
    renderCourses = (courses, language) => (
        <table className="table-schema" key={courses[0].courseId} style={styles.course}>
            <RenderCoHeader course = {courses[0]} language={language} onClick={()=>this.handleDesc(courses[0].textId)}/>    
            <RenderCoRegHeader language = {this.props.language} weekend={courses[0].courseType === 'HK'?true:undefined}/>
            <tbody>
            {courses.filter(it=>it.active !== '0').sort((a,b)=> a.dayOfWeek - b.dayOfWeek).map((course, index) => 
                    <RenderCoRegLine index={index} language = {this.props.language} course={course} />    
            )}  
            </tbody>
        </table>   
    ) 

    // Render all courses for one scheduleId
    renderSchema = (courses, withBorder) => {
        //let courseNameMap = groupBy(courses.sort((a,b)=>a.sequenceNumber - b.sequenceNumber), it => it.name)
        //let courseNames = [...courseNameMap.keys()]
        let activeCourses=courses;
        let language = this.props.language;
        let courseIdMap = groupBy(activeCourses.sort((a,b)=>a.sequenceNumber - b.sequenceNumber), it => it.courseId)
        let courseIds = [...courseIdMap.keys()]
        const eventName = activeCourses[0].eventName
        const year = activeCourses[0].year
        //let courses = this.coursesOfSchema(scheduleId); // Courses for one schema
        //let names = this.namesForCourses(courses.sort((a,b)=>a.sequenceNumber - b.sequenceNumber)); // Titles for one schema
        return (
            <div style={withBorder?styles.schedule:styles.scheduleNoBorder}> 
                <div>
                    <ShoppingCartButton productType='course' iconColor={tkColors.Olive.Light} />
                </div>        
                <h1 style={{width:'100%', textAlign:'top',color:this.state.color, fontSize:24, fontStyle:'oblique', fontWeight:'bold'}}>{eventName}&nbsp;{year}</h1>    
                {courseIds.map(courseId => (
                    this.renderCourses(courseIdMap.get(courseId), this.props.language)
                ))}
            </div>
        )
    }


    // Render 
    render() {   
        let schemaMap = groupBy(this.props.list.sort((a,b) => a.productId - b.productId), it => it.eventType+it.year)
        let scheduleIds = [...schemaMap.keys() ]
        const withBorder = scheduleIds.length > 1
        const language=this.props.language;
        const style=this.props.style.globalBackground?{...styles.root, background:this.props.style.globalBackground}:styles.root
        return(
            <div style={style}>                    
                {this.state.toggleText?
                    <div style={styles.container}>
                        <div style={styles.button}>
                            <Button variant="outlined" onClick={(scheduleId)=>this.setState({toggleText:!this.state.toggleText})}>
                                {language===LANGUAGE_SV?'Gå tillbaka'
                                :language===LANGUAGE_ES?'Para volver, haga clic.'
                                :'Go back'}
                            </Button>    
                        </div>
                        <TextShow url={'/getTexts'} style={styles.text} groupId={'Course'} textId={this.state.textId} language={language}>
                            <h4>Enter text for groupId={'Course'} and textId={this.state.textId} ...</h4>
                        </TextShow>
                    </div>
                :
                    <div>
                        {this.props.list.length > 0?
                            <div>
                                {scheduleIds.map(scheduleId =>  
                                    this.renderSchema(schemaMap.get(scheduleId, withBorder), )
                                )}
                            </div>    
                        :
                            <CircularProgressTerminate text={TEXTS.NO_SCHEMA[this.props.language]} />
                        }    
                    </div>           
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        username: '',
        password: '',
        language: state.language,
        list: state.schedule.list,
        shoppingCartList: state.shoppingCart.list,
        style:state.style, 
        url:'/scheduleCourses?language=' + state.language,
    }
}    

// Map the dispatch to onMyClick
const mapDispatchToProps = (dispatch) => {
    return {
        setList: (list) => { dispatch(setScheduleList(list)) },
    }        
}

export default connect( 
    mapStateToProps,
    mapDispatchToProps,
) (withListFromStore(withBreakpoints(ScheduleCourses), false));    
