//import React from 'react';
import axios from 'axios'

const fetchList = (username, password, url, handleList) => {
   var list = [];
   console.log('fetchList: url:' + url);
// console.log('fetchList: url:' + url + ' username:' + username + ' password:' + password);
    axios({	
        method: 'get',
        url: url, 
        auth: {
            username,
            password,
        }
      })
    .then(response => {
        console.log('------- function fetchList --------')
        console.log('url:', url)
        console.log('response:', response);
        list = response.data?response.data.result?response.data.result:[]:[];
        console.log('list.length:', list.length);
        handleList(list);
    })
    .catch(e => {
        console.log('(function: functions/fetchList) URL:', url);
        console.log('(function: functions/fetchList) Error message:', e);
        handleList(list);
    });
}


export default fetchList;
