import React, {Component} from 'react';
import { connect } from 'react-redux';
import withRecords from 'Components/Table/withRecords';
import GetRegistrationMapMap from './GetRegistrationMapMap'
import lightGreen from '@material-ui/core/colors/lightGreen';
import red from '@material-ui/core/colors/red';
import indigo from '@material-ui/core/colors/indigo';
import {PRODUCT_TYPE} from 'Settings/Const'

const GROUP_BY_DEF_ARR=[
    {
        groupByField:'productType', 
        headerFields:['productType'],
        headerProps:{
            open:{
                width:'100vw', 
                maxWidth:'100%',
                paddingTop:10, 
                paddingBottom:10, 
                backgroundColor:indigo[800],
            }, 
            imbalance:{
                backgroundColor:red[800],
            }, 
            closed:{
                width:'80vw',
                padding:2, 
                backgroundColor:indigo[800], 
            }
        }    
    },    
    {
        groupByField:'productId',
        headerFields:['dayOfWeek', 'startDate', 'startTime', 'label'], 
        listSortBy:['firstName','lastName'],
        headerProps:{
            open:{
                width:'100vw', 
                maxWidth:'100%',
                paddingTop:10, 
                paddingBottom:10, 
                backgroundColor:indigo[800],
            }, 
            imbalance:{
                backgroundColor:red[800],
            }, 
            closed:{
                width:'80vw',
                padding:2, 
                backgroundColor:indigo[800], 
            }
        }    
    },    
];

const TAB_FIELDS=['firstName', 'lastName', 'email', 'phone', 'leader', 'status', 'firstNamePartner', 'lastNamePartner', 'emailPartner', 'productType', 'productId', 'orderId', 'regDate', 'newsletter', 'deleted'];
const UPDATE_FIELDS = ['firstName', 'lastName', 'email', 'phone', 'leader', 'status', 'firstNamePartner', 'lastNamePartner', 'emailPartner', 'productId', 'newsletter', 'deleted']        

const mapStateToProps = (state) => {
    return {
        username: state.user.username,
        password: state.user.password,
        language: state.language,
        url:'/admin/getRegistration?productType=social'  + '&language=' + state.language,
        groupByDefArr:GROUP_BY_DEF_ARR,
        tableUpdate:'tbl_registration',
        urlUpdate:'/admin/updateTable',
        urlUpdateAll:'/admin/updateTableAll',
        viewFields:TAB_FIELDS,
        productType:'social',
        updateFields:UPDATE_FIELDS,
    }
}    

export default connect(mapStateToProps)(withRecords(GetRegistrationMapMap));    
