import React from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import {withRouter} from 'react-router-dom';
import tkColors from 'Settings/tkColors';
import { LANGUAGE_SV, LANGUAGE_ES} from 'redux/actions/actionsLanguage' 
import { updateRegistration } from 'redux/actions/actionsShop'
import RegistrationForm from './RegistrationForm'
import Weekdays from 'Settings/Weekdays';
import {AVA_STATUS, BOOKING_STATUS, EVENT_STATUS, PRODUCT_TYPE} from 'Settings/Const'
import config from 'Settings/config';
import postData from 'functions/postData'
import fetchList from 'functions/fetchList'
import Button from 'Components/Button'
import {setGlobalStyle} from 'redux/actions/actionsStyle'
import TextShow from 'Components/Text/TextShow'
import moment from 'moment-with-locales-es6'


const CULTURE = (language) => language===LANGUAGE_SV?'sv':language===LANGUAGE_ES?'es':'en'

const TEXTS={
    GO_BACK:{
        SV:'Gå tillbaka',
        EN:'Go back',
        ES:'Regresar',
    },
    REGISTRATION_READY: (reg) => {


        if (reg.firstNamePartner?reg.firstNamePartner.length > 0:false) {
            return({
                SV:'Du och din danspartner är nu anmälda till '
                    + reg.nameSV + ' i ' + reg.city  + ' som startar ' + reg.startDate + ' klockan ' + reg.startTime,
                EN:'You and your dance partner are now registered on '  
                    + reg.nameEN?reg.nameEN:reg.nameSV + ' in ' + reg.city + ' starting ' + reg.startDate + ' at ' + reg.startTime,
                ES:'Usted y su pareja de baile ya están registrados para el '  
                    + reg.nameES?reg.nameES:reg.nameSV + ' en ' + reg.city + ' eso comienza ' + reg.startDate + ' a las ' + reg.startTime,
            })
        } else {   
            return({
                SV:'Du är nu anmäld till ' + reg.nameSV + ' i ' + reg.city + ' som startar ' + reg.startDate + ' klockan ' + reg.startTime,
                EN:'You are now registered on '  + reg.nameSV + ' in ' + reg.city + ' starting ' + reg.startDate + ' at ' + reg.startTime,
                ES:'Ahora estás registrado para el '  + reg.nameSV + ' en ' + reg.city + ' eso comienza ' + reg.startDate + ' a las ' + reg.startTime,
            })
        }    
    },
    LEGAL: {
        SV:`Vänligen kontrollera din mailbox (och spam-mailbox) så att du fått din registrering bekräftad. 
        Om så ej skett vänligen kontakta tangokompaniet på mail eller telefon.`,
        EN:`Por favor revise su buzón para confirmar su registro.
        De lo contrario, comuníquese con la compañía de tango por correo o teléfono.`,
        ES:`Please check your mailbox (and span mailbox) to confirm that you registration is confirmed.
        If you got no mail check your spam mailboxn la compañía de tango por correo o teléfono.`
    },
    ORDER_ID: (orderId) => ({
        SV:`Din registrering har ordernummer:` + orderId, 
        EN:`Your registration has order number:` + orderId,
        ES:`Su número de pedido es:` + orderId,
    }), 
    ANGE_ORDER_ID: {
        SV:`Ange ordernumret i meddelande fältet när du betalar via Internet (SWISH nummer: 123 173 30 05)`, 
        EN:`Enter the order number in the message field when paying via the Internet (SWISH number: 123 173 30 05)`,
        ES:`Ingrese número en el campo del mensaje cuando pague a través de Internet (SWISH numero: 123 173 30 05)`,
    }, 
    NO_SUCH_COURSE: productId => ({
        SV:'Kurs med productId ' + productId + ' existerar inte i nuvarande schema', 
        EN:'This productId '  + productId + ' is not found for any course in the schedule',
        ES:'This productId ' + productId + ' is not found for any course in the schedule',
    })
}

const apiBaseUrl=config[process.env.NODE_ENV].apiBaseUrl;
const CREATE_REG_URL = apiBaseUrl + '/createRegistration';
const POST_FORM_URL = apiBaseUrl + '/postForm';
 
const styles = {
    root: {
        flex:1,
        padding:5,
        maxWidth:1000,
        marginRight:'auto',
        marginLeft:'auto',
    },
    warning: {
        backgroundColor:'yellow',
        color:'red'
    },
    textContainer:{
        display:'flex',
        flexDirection:'column',
        flexWrap:'wrap',
        color:tkColors.Text.Dark
    },
    text : {
        flex:1,
        padding: 5,
        textAlign:'left',
        fontSize:'small',
        minWidth:300,
    }
}

class Registration extends React.Component {   
    
    constructor() {
        super();
        this.state = {linkTo:false, serverReady:false, textOK:undefined, orderId:0, course:undefined, slept:false};
        this.handlePutInCart = this.handlePutInCart.bind(this);
        this.handleReply = this.handleReply.bind(this);
        this.emulateReg = this.emulateReg.bind(this)
        this.emulateRegSocial = this.emulateRegSocial.bind(this)
        this.fetchCourseForProductId = this.fetchCourseForProductId.bind(this)
    }

    timeout(ms) { //pass a time in milliseconds to this function
        return new Promise(resolve => setTimeout(resolve, ms));
    }


    fetchCourseForProductId() {
        const productId = this.props.match.params.productId;
        const fullPath = apiBaseUrl + "/scheduleCourses?productId=" + productId
        fetchList('', '', fullPath, (list)=> {
            const course = list.find(it =>it.productId === productId)
            console.log('Registration->fetchCourseForProductId->course', course)
            this.setState({course})
        })
    }

    componentDidMount () {
        console.log('componentDidMount')
        moment.locale(CULTURE(this.props.language?this.props.language:LANGUAGE_SV))
        this.fetchCourseForProductId()

    }  

    componentWillReceiveProps(nextProps) {
        if (nextProps.language !== this.props.language) {
            moment.locale(CULTURE(nextProps.language?nextProps.language:LANGUAGE_SV))
            console.log('Registration->componentWillReceiveProps->language', nextProps.language)
        }
    }   

    bookingStatus(leader, reg) {
        return(
            leader && reg.avaStatus === AVA_STATUS.CLOSED_FOR_LEADER?
                BOOKING_STATUS.CONFIRMED
                // BOOKING_STATUS.WAITLISTED_FOR_FOLLOWER
            :!leader && reg.avaStatus === AVA_STATUS.CLOSED_FOR_FOLLOWER?
                BOOKING_STATUS.CONFIRMED
                // BOOKING_STATUS.WAITLISTED_FOR_LEADER
            :reg.avaStatus === AVA_STATUS.WAITLIST_FOR_SPACE?
                BOOKING_STATUS.WAITLIST_FOR_SPACE
                // BOOKING_STATUS.WAITLISTED_FOR_CANCELLATION
            :reg.courseFull?
                BOOKING_STATUS.CONFIRMED
                // BOOKING_STATUS.WAITLISTED_FOR_CANCELLATION
            :
                BOOKING_STATUS.CONFIRMED
        )
    }

    debitable(status) {
        return status === BOOKING_STATUS.CONFIRMED
    }

    createRegistration(values, reg) {
        const status=this.bookingStatus(values.leader, reg); 
        const productList = [reg]
        // const debitable=this.debitable(status);
        const registration = {...reg, ...values, status, language:this.props.language, index:0, debitable:false, showInCart:false, deleted:false, productList}
        console.log('Registration:', registration);
        return(registration);
    }    

    handlePutInCart = (values, reg) => {
        let regUpd = this.createRegistration(values, reg);
        console.log('Updated registration object:', regUpd);
        this.setState({linkTo:values.linkTo});
        this.props.updateRegistration(regUpd);
    }

    handleReply(data) {
        this.props.setRegistrationButtonColor('green')
        data?data.status?data.status==='OK'?this.setState({serverReady:true, orderId: data.orderId})  
        :data.status==='ERROR'?alert('ERROR: Registration failed with status: ' + data.status + '. Please contact Tangokompaniet ...')
        :alert('ERROR: Registration status is ' + data.status + '. Please contact tangokompaniet on mail')
        :alert('ERROR: No return status (data.status) from server')
        :alert('ERROR: No data returned from server')
    }

    handleRegisterNow = (values, reg) => {

        const data = this.createRegistration(values, reg);
          // After posting order handle the checkout
        this.props.setRegistrationButtonColor('yellow')
        this.setState({textOK:TEXTS.REGISTRATION_READY(data)[this.props.language]})
        postData(CREATE_REG_URL, this.props.username, this.props.password, data, this.handleReply);
    }

    renderTitle = (reg) => {
        return(
        reg?
            <h3>
                {reg.name}&nbsp;
                {reg.city}&nbsp;
                {Weekdays[this.props.language][reg.dayOfWeek-1]}&nbsp;
                {reg.startTime}&nbsp; 
                {this.props.language===LANGUAGE_SV?'Startar:':this.props.language===LANGUAGE_ES?'De inicio':'Starts:'}{reg.startDate}
            </h3>
        :
            <h3 style={styles.warning}>
                Warning: No  choosen !
            </h3>
        
        )
    }    

    emulateRegSocial() {
        const productId = this.props.match.params.productId
        const productType = this.props.match.params.productType?this.props.match.params.productType:PRODUCT_TYPE.SOCIAL
        const eventStatus = this.props.match.params.eventStatus?this.props.match.params.eventStatus:EVENT_STATUS.OPEN
        moment.locale(CULTURE(this.props.language?this.props.language:LANGUAGE_SV))
        const mom = moment(productId.substring(0, 10), 'YYMMDDHHmm')
        const siteId = productId.substr(10,2)
        return({
            productId,
            productType,
            siteId,
            eventStatus,
            title:productId.substring(12), 
            startDate: mom.format('DDMMMYY'),
            startTime: mom.format('LT'),
            dayname: mom.format('dddd'),
            dayOfWeek: mom.isoWeekday(),
            city: (siteId === 'VG'||siteId === 'SV'||siteId === 'MH')?'Lund':'Malmö',
            noLastNamePartner: true,
            noEmailPartner: true,
            noPhonePartner: true,
            status:BOOKING_STATUS.CONFIRMED, 
            avaStatus:AVA_STATUS.OPEN,
        })    
    }    

    emulateReg = () => {
        const productType = this.props.match.params.productType
        switch (productType) {
            case 'course':
                return this.state.course
            default:        
                return this.emulateRegSocial()
            }
    }

    render() {  
        const language = this.props.language?this.props.language:LANGUAGE_SV
        const productType = this.props.match.params.productType
        const productId = this.props.match.params.productId
        const reg=this.props.shoppingCartList.length > 0?this.props.shoppingCartList.find(it => it.productType===productType && it.productId === productId):this.emulateReg()
        return (
                <div style={styles.root}>
                    {reg?
                        this.state.serverReady===true?
                            <div style = {{color:tkColors.Purple.Light, textAlign:'center', verticalAlign:'middle'}}>
                                <h3>
                                    {this.state.textOK}
                                </h3>
                            <div style={styles.textContainer}>
                                <div style={styles.text}>{TEXTS.ORDER_ID(this.state.orderId)[language]}</div>
                                <div style={styles.text}>{TEXTS.ANGE_ORDER_ID[language]}</div>
                                <TextShow style={styles.text} url={'/getTexts'} groupId={'REGISTRATION'} textId={'IF_NO_MAIL'} language={language} />
                                {productType === PRODUCT_TYPE.COURSE?<TextShow style={styles.text} url={'/getTexts'} groupId={'REGISTRATION'} textId={'LEGAL'} language={language} />:null}    
                            </div>
                            <Button 
                                variant="outlined"
                                onClick={()=>{this.props.history.goBack()}}
                            >
                                {TEXTS.GO_BACK[language]}
                            </Button>      
                          </div>
                        :this.state.linkTo?
                            <div>
                                <Redirect to={this.state.linkTo} />
                            </div>
                        :    
                            <div>
                                <RegistrationForm 
                                    reg={reg} 
                                    handleRegisterNow={(values)=>this.handleRegisterNow(values, reg)} 
                                    handlePutInCart={(values)=>this.handlePutInCart(values, reg)} 
                                    handleGoBack={(linkTo)=>this.props.history.push(linkTo)} 
                                />
                            </div>
                        :
                            this.state.slept?<h3 style = {{color:tkColors.Purple.Light, textAlign:'center', verticalAlign:'middle'}}>{TEXTS.NO_SUCH_COURSE(productId)[language]}</h3>:''
                    }
                    </div>  
        )
     };    
};
const mapStateToProps = state => {
    return {
      language: state.language,
      shoppingCartList: state.shoppingCart.list,
      username:state.user.username,
      password:state.user.password,
      style:state.style,
    }    
}

const mapDispatchToProps = dispatch => {
    return {
        updateRegistration: reg => dispatch(updateRegistration(reg)),
        setRegistrationButtonColor: color =>  { dispatch(setGlobalStyle({registrationButtonColor:color})) },
    }
}


export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Registration))    



