import React from 'react';
import { withBreakpoints } from 'react-breakpoints'


let styles = {
    th: {
        verticalAlign:'bottom',
        margin:10,
        height:12,
        fontSize:'small',
        fontWeight: 'lighter',
    },
};


export const renderRegistrationHeaderSmall = (language, weekend) => {
    return(
        <tr >
            {weekend?null
            :
                <>
                <th style={styles.th}>{language==='SV'?'Veckodag':language==='EN'?'Day':'Día'}</th>
                <th style={styles.th}>{language==='SV'?'Tid':language==='EN'?'Time':'Tiempo'}</th>
                </>
            }    
            <th style={styles.th}>{language==='SV'?'Stad':language==='EN'?'City':'Ciudad'}</th>
            <th style={styles.th}>{language==='SV'?'Kursstart':language==='EN'?'Start':'Comienzo'}</th>
            <th style={styles.th}>{language==='SV'?'Lärare':language==='EN'?'Teachers':'Maestro'}</th>
            <th style={styles.th}>{language==='SV'?'Anmälan':language==='EN'?'Register':'Aplicación'}</th>
        </tr>
    )    
}     

const _renderRegistrationHeaderLarge = (language, weekend) => {
    return(
    <tr>
        {weekend?null
        :
            <>
                <th style={styles.th}>{language==='SV'?'Veckodag':language==='EN'?'Day':'Día'}</th>
                <th style={styles.th}>{language==='SV'?'Tid':language==='EN'?'Time':'Tiempo'}</th>
            </>
        }
        <th style={styles.th}>{language==='SV'?'Stad':language==='EN'?'City':'Ciudad'}</th>
        <th style={styles.th}>{language==='SV'?'Kursstart':language==='EN'?'Start':'Comienzo'}</th>
        <th style={styles.th}>{language==='SV'?'Lärare':language==='EN'?'Teachers':'Maestro'}</th>
        <th style={styles.th}>{language==='SV'?'Anmälan':language==='EN'?'Register':'Aplicación'}</th>
    </tr>
    )    
}     

const RenderRegistrationHeader = (props) => {
    const {breakpoints, currentBreakpoint, language, weekend} = props;
    return(breakpoints[currentBreakpoint] < breakpoints.tablet?renderRegistrationHeaderSmall(language, weekend):_renderRegistrationHeaderLarge(language, weekend));
}

export default withBreakpoints(RenderRegistrationHeader)